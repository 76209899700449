import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalMenuType, WalkthroughStep } from '@typs/app'
import { AppState } from '@typs/store'

const initialState: AppState = {
  tourState: {
    visible: false,
    currentStep: 0,
    completed: false,
    activeComponent: null
  },
  walkthroughMenu: null,
  modalMenu: null,
  search: { query: '', results: [] }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTourActiveComponent: (
      state,
      action: PayloadAction<AppState['tourState']['activeComponent']>
    ) => {
      state.tourState.activeComponent = action.payload
    },
    startTour: state => {
      state.tourState.completed = false
      state.tourState.visible = true
      state.tourState.currentStep = 0
      state.tourState.activeComponent = null
    },
    restartTour: state => {
      state.tourState.completed = false
      state.tourState.currentStep = 0
      state.tourState.completed = false
      state.tourState.activeComponent = null
    },
    preEndTour: state => {
      state.tourState.completed = true
      state.tourState.activeComponent = null
    },
    endTour: state => {
      state.tourState.visible = false
      state.tourState.activeComponent = null
    },
    moveTour: (state, action: PayloadAction<'forward' | 'backward'>) => {
      if (action.payload === 'forward') {
        state.tourState.currentStep += 1
      } else {
        state.tourState.currentStep -= 1
      }
    },
    updateWalkthoughMenu: (state, action: PayloadAction<WalkthroughStep>) => {
      state.walkthroughMenu = action.payload
    },
    setSearchResults: (state, action: PayloadAction<AppState['search']>) => {
      state.search = action.payload
    },
    updateModalMenu: (state, action: PayloadAction<ModalMenuType>) => {
      state.modalMenu = action.payload
    }
  }
})

export const {
  updateModalMenu,
  updateWalkthoughMenu,
  setSearchResults,
  startTour,
  endTour,
  moveTour,
  preEndTour,
  restartTour,
  setTourActiveComponent
} = appSlice.actions

export default appSlice.reducer
