import ErrorBoundary from '@components/ErrorFallback/ErrorBoundary'
import Protected from '@components/navigation/Protected'
import UnProtected from '@components/navigation/UnProtected'
import NoMatch from '@components/navigation/NoMatch'
import { lazy } from '@constants/react-lazy-with-reload'
import { Outlet } from 'react-router'
import routes from './routesList'

// *** Outside of Dashboard ***
const DataDeletion = lazy(() => import('@pages/Landing/data-deletion'))
const ComingSoon = lazy(() => import('@pages/ComingSoon'))
const Landing = lazy(() => import('@pages/Landing'))
const Login = lazy(() => import('@pages/Landing/login'))
const SignUp = lazy(() => import('@pages/Landing/signup'))
const PrivacyPolicy = lazy(() => import('@pages/Landing/privacy-policy'))
const Terms = lazy(() => import('@pages/Landing/terms'))
const OnboardSignupDialogPage = lazy(() => import('@pages/Landing/onboarding'))
const FrequentlyAskedQuestions = lazy(() => import('@pages/Landing/faqs'))

// *** Inside Dashboard ***
import Dashboard from '@components/pages/Dashboard/Dashboard'
const ProfilePageWrapper = lazy(
  () => import('@components/pages/Dashboard/home/ProfilePageWrapper')
)
const Home = lazy(() => import('@pages/Dashboard/home'))
const Discover = lazy(() => import('@pages/Dashboard/discover'))
const SearchResultList = lazy(() => import('@pages/Dashboard/search'))
const Notifications = lazy(() => import('@pages/Dashboard/notifications'))
const OtherUserProfile = lazy(
  () => import('@pages/Dashboard/profile/OtherUserProfile')
)
const Profile = lazy(() => import('@pages/Dashboard/profile'))
const SocialAssetsList = lazy(
  () => import('@pages/Dashboard/profile/SocialAssetsList')
)
const UserPosts = lazy(() => import('@pages/Dashboard/posts/UserPosts'))
const Settings = lazy(
  () => import('@components/pages/Dashboard/settings/Settings')
)
const BuyPoints = lazy(() => import('@pages/Dashboard/points/BuyPoints'))
const EditProfile = lazy(() => import('@pages/Dashboard/settings/EditProfile'))
const EditNotifications = lazy(
  () => import('@pages/Dashboard/settings/EditNotifications')
)
const Security = lazy(() => import('@pages/Dashboard/settings/Security'))
const PostDetail = lazy(() => import('@pages/Dashboard/posts/PostDetail'))

/** Skeleton */
import DashboardSkeleton from '@components/Skeletons/dashboard/home'
import NotificationsSkeleton from '@components/Skeletons/dashboard/home/notifications'
import AllPostsSkeleton from '@components/Skeletons/dashboard/post/AllPostsSkeleton'
import PostDetailSkeleton from '@components/Skeletons/dashboard/post/PostDetailSkeleton'
import ProfilePageSkeleton from '@components/Skeletons/dashboard/profile'
import EditProfileSkeleton from '@components/Skeletons/dashboard/settings/EditProfileSkeleton'
import ManageSecuritySkeleton from '@components/Skeletons/dashboard/settings/ManageSecuritySkeleton'
const Content = lazy(() => import('@components/navigation/Content'))
import AppRedirect from '@pages/Landing/app-redirect'

const MaoneyRoutes = [
  { path: routes.appRedirect, element: <AppRedirect /> },
  {
    path: '/',
    element: (
      <UnProtected>
        <Outlet></Outlet>
      </UnProtected>
    ),
    children: [
      {
        path: '/',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Landing />
          </ErrorBoundary>
        )
      },
      {
        path: '/coming-soon',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <ComingSoon />
          </ErrorBoundary>
        )
      },
      {
        path: '/privacy-policy',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <PrivacyPolicy />
          </ErrorBoundary>
        )
      },
      {
        path: '/terms',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Terms />
          </ErrorBoundary>
        )
      },
      {
        path: '/data-deletion',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <DataDeletion />
          </ErrorBoundary>
        )
      },
      {
        path: routes.onboarding,
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <OnboardSignupDialogPage />
          </ErrorBoundary>
        )
      },
      {
        path: routes.signup,
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <SignUp />
          </ErrorBoundary>
        )
      },
      {
        path: '/login',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Login />
          </ErrorBoundary>
        )
      },
      {
        path: '/faqs',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <FrequentlyAskedQuestions />
          </ErrorBoundary>
        )
      }
    ]
  },
  // / ################## END UNPROTECTED ROUTES ##################
  {
    path: '/dashboard',
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
    children: [
      {
        path: 'home',
        element: (
          <ErrorBoundary customFallBackComponent={<DashboardSkeleton />}>
            <Content>
              <Home />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'search',
        element: (
          <ErrorBoundary>
            <Content>
              <SearchResultList />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'discover',
        element: (
          <ErrorBoundary>
            <Content>
              <Discover />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'notifications',
        element: (
          <ErrorBoundary customFallBackComponent={<NotificationsSkeleton />}>
            <Content>
              <Notifications />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'post/user/:id',
        element: (
          <ErrorBoundary customFallBackComponent={<AllPostsSkeleton />}>
            <Content>
              <UserPosts />
            </Content>
          </ErrorBoundary>
        )
      },
      // profile
      {
        path: 'profile',
        element: (
          <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
            <Content>
              <ProfilePageWrapper />
            </Content>
          </ErrorBoundary>
        ),
        children: [
          { path: '', element: <Profile /> },
          { path: 'social-assets', element: <SocialAssetsList /> }
        ]
      },
      // other user profile
      {
        path: 'user/:id',
        element: (
          <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
            <Content>
              <ProfilePageWrapper />
            </Content>
          </ErrorBoundary>
        ),
        children: [
          { path: '', element: <OtherUserProfile /> },
          { path: 'social-assets', element: <SocialAssetsList /> }
        ]
      },
      {
        path: 'settings/profile',
        element: (
          <ErrorBoundary customFallBackComponent={<EditProfileSkeleton />}>
            <Content>
              <Settings>
                <EditProfile />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'settings/security',
        element: (
          <ErrorBoundary customFallBackComponent={<ManageSecuritySkeleton />}>
            <Content>
              <Settings>
                <Security />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'settings/notifications/:notifType',
        element: (
          <ErrorBoundary customFallBackComponent={<ManageSecuritySkeleton />}>
            <Content>
              <Settings>
                <EditNotifications />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'posts/:postId',
        element: (
          <ErrorBoundary customFallBackComponent={<PostDetailSkeleton />}>
            <Content>
              <PostDetail />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'points/purchase',
        element: (
          <ErrorBoundary customFallBackComponent={<PostDetailSkeleton />}>
            <Content>
              <BuyPoints />
            </Content>
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: '*',
    element: <NoMatch />
  }
]

export default MaoneyRoutes
