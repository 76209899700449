import PlainLogo from '@components/shared/PlainLogo'
import { appLinks } from '@constants/app'
import { useEffect, useMemo, useRef, useState } from 'react'

function detectDevice() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any)?.opera

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (userAgent?.includes('Mac OS')) {
    return 'iOS'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
    return 'iOS'
  }

  return 'other'
}

const AppRedirect = () => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const [deviceType] = useState<'Android' | 'iOS' | 'other' | null>(() =>
    detectDevice()
  )
  const link = useMemo(() => {
    if (deviceType === 'Android' || deviceType === 'other') {
      return appLinks.android
    }
    if (deviceType === 'iOS') {
      return appLinks.ios
    }
  }, [deviceType])

  useEffect(() => {
    if (link && linkRef?.current) {
      linkRef?.current?.click()
    }
  }, [link])

  return (
    <div className="bg-white h-screen flex items-center justify-center">
      <PlainLogo />
      <a href={link} ref={linkRef} className="hidden"></a>
    </div>
  )
}

export default AppRedirect
