import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { formatLocation, getProfileImageUrl } from '@utils/helpers'
import { LoyaltyPointType, UserProfileType } from 'types/users'
import { AuthState } from '@typs/store'

const initialState: AuthState = {
  user: null,
  jwt: '',
  loyaltyPoints: [],
  totalLoyaltyPoints: 0
}

/** Reducer to manage the auth state and data of the currently logged in user */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateUser: (
      state,
      action: PayloadAction<{ user: UserProfileType; jwt?: string }>
    ) => {
      const userData = action.payload.user
      state.user = {
        ...userData,
        picture: getProfileImageUrl(userData.picture),
        country: formatLocation(userData.country),
        zip_code: formatLocation(userData.zip_code),
        state: formatLocation(userData.state)
      }
      if (action.payload.jwt) {
        state.jwt = action.payload.jwt
      }
    },
    setLoyaltyPoints: (
      state,
      action: PayloadAction<{
        loyaltyPointsBreakdown?: LoyaltyPointType[]
        totalLoyaltyPoints?: number
      }>
    ) => {
      if (action.payload.loyaltyPointsBreakdown) {
        state.loyaltyPoints = action.payload.loyaltyPointsBreakdown
      }
      if (action.payload.totalLoyaltyPoints) {
        state.totalLoyaltyPoints = action.payload.totalLoyaltyPoints
      }
    },
    logUserOut: state => {
      localStorage.clear()
      state.user = null
      state.jwt = ''
      setTimeout(() => {
        localStorage.clear()
      }, 1000)
    }
  }
})

export const { authenticateUser, logUserOut, setLoyaltyPoints } =
  authSlice.actions

export default authSlice.reducer
