import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { customBaseQuery } from './util'

export const notificationsService = createApi({
  reducerPath: 'notificationsService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getNotificationSettings: builder.query({
      query: id => ({
        url: `${apiConfig?.User?.GetNotificationSettings}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
    }),
    getNotification: builder.query({
      query: id => ({
        url: `${apiConfig?.User?.GetNotifications}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
    })
  })
})

export const {
  useLazyGetNotificationSettingsQuery,
  useGetNotificationQuery,
  useLazyGetNotificationQuery
} = notificationsService
