const { VITE_FIREBASE } = import.meta.env
const apiConfig = {
  BaseUrl:
    import.meta.env.VITE_ENV === 'production'
      ? import.meta.env.VITE_APP_PROD_URL
      : import.meta.env.VITE_ENV === 'staging'
        ? import.meta.env.VITE_APP_STAGING_URL
        : import.meta.env.VITE_APP_DEV_URL,
  SocketUrl:
    import.meta.env.VITE_ENV === 'production'
      ? import.meta.env.VITE_APP_PROD_SOCKET_URL
      : import.meta.env.VITE_ENV === 'staging'
        ? import.meta.env.VITE_APP_STAGING_SOCKET_URL
        : import.meta.env.VITE_APP_DEV_SOCKET_URL,
  GoogleApi: import.meta.env.VITE_APP_GOOGLE_API,
  Faq: `/api/faqs`,
  Search: (query: string) => `/api/fuzzy-search/search?query=${query}`,
  Tribes: {
    TribesUrl: `/api/tribes`,
    MemberUrl: `/api/members`,
    PermissionsUrl: `/api/tribe-permissions`,
    CreateTribe: `/api/tribe-service/createTribe`
  },
  Chat: {
    ChatRoomMember: '/api/chat-room-members',
    ChatRoom: '/api/chat-rooms',
    ChatMessage: '/api/chat-messages'
  },
  Landing: {
    GetTopSection: `/api/top-section-collections?populate=*`,
    GetDecentralizedBanking: `/api/decentralized-banking-collections?populate=*`,
    GetTestimony: `/api/testimonies?populate=*`,
    GetPress: `/api/presses?populate=*`,
    GetUserCount: `api/users-count`,
    Blog: `/api/articles`
  },
  Legal: {
    GetTerms: `/api/terms-and-condition?populate=*`,
    GetPrivacy: `/api/privacy?populate=*`
  },
  Onboarding: {
    Login: `/api/users/signin`,
    SignUp: `/api/users/signup`,
    SendOtp: `/api/users/send-otp`,
    VerifyOTP: '/api/users/verify-otp',
    ResendOTP: '/api/users/resend-otp',
    UserPreference: `/api/user-preferences`,
    OTP: `/api/otps`,
    FB_SendOTP: `/maoneyOTPMain`,
    FB_BaseUrl: `${VITE_FIREBASE}`,
    RequestPassword: `/api/auth/forgot-password`,
    ResetPassword: `/api/auth/reset-password`,
    ChangePassword: `/api/auth/change-password`,

    SocialLogin: (provider: string, access_token: string) =>
      `/api/auth/${provider}/callback?access_token=${access_token}`
  },
  User: {
    Users: `/api/users`,
    Me: `/api/users/me`,
    Points: `/api/maoney-points`,
    SocialPoints: `api/social-points`,
    Reference: `/api/user-references`,
    Organisations: `/api/user-organisations`,
    Locations: `/api/user-locations`,
    Upload: `/api/upload`,
    CreatePost: `/api/posts/create`,
    GetPost: `/api/posts/all`,
    Posts: `/api/posts`,
    GetOneUser: `/api/users/get-users/1/1`,
    GetUsers: (from: number, to: number) =>
      `/api/users/get-users/${from}/${to}`,
    DeletePost: `api/posts/delete`,
    UpdatePost: `api/posts/edit`,
    UpdateProgress: (postId: number) => `api/posts/edit-progress/${postId}`,
    ProgressPost: `api/posts/add-progress`,
    GetPostById: `api/posts`,
    Comments: `api/comments`,
    Reactions: `api/reactions`,
    Products: `api/products`,
    UpdateProfile: `api/users/update-profile`,
    UserConnection: `/api/connections/handle-connection-request`,
    RequestForConnection: `/api/connections/request-to-user`,
    GoogleLogin: `/api/users/google-signup`,
    LoyaltyPoints: `/api/loyalty/allocate/`,
    GetSocialAssets: '/api/connections/get-user-connections',
    GetAllAssets: '/api/connections/get-connections',
    CategorizeAssets: '/api/connections/categorize-connection',
    UpdateAssets: '/api/connections/handle-connection-request',
    GetUserProfileById: `/api/users/get-user-by-id/`,
    ModifyNotifications: '/api/notifications/modify-setting',
    GetNotifications: '/api/notifications/get-user-notifications',
    GetNotificationSettings: '/api/notifications/notification-setting',
    LikePost: `/api/posts/like`,
    UnLikePost: `/api/posts/unlike`,
    LikeComment: `/api/posts/like-comment`,
    UnLikeComment: `/api/posts/unlike-comment`,
    PostComments: `/api/posts/create/comment`,
    EditComment: `/api/posts/edit/comment`,
    DeleteComment: `/api/posts/delete/comment`,
    ReplyComment: `/api/posts/comments/reply`,
    GetComments: `/api/posts/comments`,
    SharePost: `/api/posts/share`,
    ChangeIdentifier: `/api/users/change-identifier`,
    DeleteAccount: `/api/users/delete-user-account`,
    GetUserPosts: (userId: number, lowLimit: number, upperLimit: number) =>
      `api/posts/user-posts/${userId}?commentPage=1&commentPageSize=10&low_limit=${lowLimit}&upper_limit=${upperLimit}`,
    verifyChangeIdentifier: (userId: number) =>
      `/api/users/verify-change-identifier/${userId}`
  },
  Notification: {
    GetNotifications: '/api/notifications/get-user-notifications'
  },
  Networks: {
    PublicProfileNetwork: `/api/public-profile-networks`,
    PeopleYouMayKnow: `/api/peopleYouMayKnow`,
    Networks: `api/networks`,
    NetworkRequest: `api/network-requests`,
    UserNetworks: `api/getUserNetworks`,
    UpdateConnectionType: (id: number) =>
      `/api/networks/${id}/update-connection-type`,
    UpdateConnectionTypeByUserId: `/api/networks/update-connection-type-by-userid`,
    DisconnectNetwork: (id: number) => `/api/networks/${id}/disconnect-toggle`,
    MuteNetwork: (id: number) => `/api/networks/${id}/mute-toggle`,
    AcceptConnectionRequest: (id: number) =>
      `/api/request/${id}/accept-request`,
    UserNetworkCounter: `/api/network/count`,
    UserNetworkRequests: `api/user-network-requests`,
    DisconnectAsset: `/api/connections/cancel-delete-connection-request`,
    MuteAsset: `/api/connections/mute-connection`,
    UnmuteAsset: `/api/connections/unmute-connection`,
    GetConnectionCategories: `/api/connections/get-categories`
  },
  SocialPoints: {
    TopTenWealthiest: `/api/top-ten-social-wealthiest`
  },
  Properties: {
    ConnectionTypes: `/api/connection-types`
  },
  SendEmail: {
    send: `/api/email`
  }
}

export default apiConfig
