import { createSlice } from '@reduxjs/toolkit'

const initialState: any = []

// Function to extract ID from a nested data object with dynamic key
const extractId = (data: any) => {
  for (const key in data) {
    if (data[key]?.id) {
      return data[key].id
    }
  }
  return null
}

const notificationsSlice = createSlice({
  name: 'notifs',
  initialState,
  reducers: {
    setNotifs: (state, { payload }) => {
      const incomingNotifs = payload.map((newNotif: any) => ({
        ...newNotif,
        isRead: false // Mark new notifications as unread
      }))

      const incomingNotifIds = incomingNotifs.map((notif: any) =>
        extractId(notif.data)
      )

      // Update existing notifications or add new ones
      const updatedState = state.map((existingNotif: any) => {
        const existingNotifId = extractId(existingNotif.data)
        if (incomingNotifIds.includes(existingNotifId)) {
          return {
            ...existingNotif
            // You might want to update the notification data here if necessary
            // For example: return {...existingNotif, ...incomingNotif};
          }
        }
        return existingNotif
      })

      // Add new notifications that are not in the existing state
      const newNotifs = incomingNotifs.filter(
        (incomingNotif: any) =>
          !state.some(
            (existingNotif: any) =>
              extractId(existingNotif.data) === extractId(incomingNotif.data)
          )
      )

      // Remove notifications that no longer exist in the incoming payload
      const filteredState = updatedState.filter((notif: any) =>
        incomingNotifIds.includes(extractId(notif.data))
      )

      return [...newNotifs, ...filteredState]
    },
    clearNotifs: () => {
      return []
    },
    updateNotifs: (state, { payload }) => {
      state.unshift({
        ...payload,
        isRead: false,
        isViewed: false
      })
    },
    markNotifsAsRead: state => {
      return state.map((notif: any) => ({
        ...notif,
        isRead: true
      }))
    },
    markNotifsAsViewed: state => {
      return state.map((notif: any) => ({
        ...notif,
        isViewed: true
      }))
    },
    deleteNotifsByIds: (state, { payload: idsToDelete }) => {
      return state.map((notif: any) =>
        idsToDelete.includes(extractId(notif.data))
          ? { ...notif, isDeleted: true }
          : notif
      )
    },
    markNotifsAsReadByIds: (state, { payload: idsToMarkAsRead }) => {
      return state.map((notif: any) =>
        idsToMarkAsRead.includes(extractId(notif.data))
          ? { ...notif, isRead: true }
          : notif
      )
    }
  }
})

const { actions } = notificationsSlice
export const {
  setNotifs,
  clearNotifs,
  updateNotifs,
  markNotifsAsRead,
  deleteNotifsByIds,
  markNotifsAsReadByIds,
  markNotifsAsViewed
} = actions
export default notificationsSlice.reducer
