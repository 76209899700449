import ReactSelect, {
  type Props,
  type ClassNamesConfig,
  type GroupBase
} from 'react-select'
import { type RefCallBack } from 'react-hook-form'
import clsx from 'clsx'
import { OptionType } from '@typs/app'

export const customClassNames = (
  hasError: boolean,
  controlClassName?: string
): ClassNamesConfig => ({
  input: () => '!shadow-none !ring-none',
  valueContainer: () => '!px-0',
  control: state =>
    clsx(
      'h-[58px] !cursor-pointer !px-4 !border !border-[#928B80] !rounded-lg !text-base !leading-6 !text-[black]',
      controlClassName,
      {
        '!border-[red]': hasError,
        '!shadow-none': state.isFocused
      }
    ),
  option: state =>
    clsx('hover:bg-primary/[.4]', {
      '!bg-primary': state.data === state.selectProps.value
    }),
  placeholder: () => '!text-base !leading-6 !text-[#928B80]'
})

interface SelectProps {
  label: string
  hasError?: boolean
  ref_?: RefCallBack
  controlClassName?: string
}

export default function Select<
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & SelectProps) {
  const { label, hasError, ref_, ...rest } = props

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-base leading-6 text-_140C00">{label}</label>
      <ReactSelect
        ref={ref_}
        classNames={customClassNames(!!hasError, props.controlClassName) as any}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null
        }}
        styles={{
          input: base => ({
            ...base,
            'input:focus': {
              boxShadow: 'none'
            }
          })
        }}
        {...rest}
      />
    </div>
  )
}
