import linkedin from '@assets/icons/linkedin.svg'
import facebook from '@assets/icons/facebook-outlined.svg'
import instagram from '@assets/icons/instagram.svg'
import twitter from '@assets/icons/twitter.svg'
import discover from '@assets/icons/discover-black.svg'
import bell from '@assets/icons/bell-black.svg'
import logout from '@assets/icons/logout.svg'
import settings from '@assets/icons/settings-white.svg'
import { UserType } from '@typs/users'
import { ReactourProps } from 'reactour'

export const socials = [
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/maoney/',
    icon: linkedin
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/maoneyinc',
    icon: facebook
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/maoney_inc/',
    icon: instagram
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/MaoneyInc',
    icon: twitter
  }
]

export const fakeUser: UserType = {
  id: 0,
  username: null,
  email: 'anon@maoney.com',
  first_name: 'User',
  last_name: 'User',
  org_name: null,
  national_id: null,
  passport_number: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  country: null,
  phone_number: null,
  date_of_birth: null,
  gender: null,
  occupation: null,
  marital_status: null,
  picture: null,
  bio: null,
  location_status: 0,
  is_verified: false,
  verification_code: null,
  reset_token: null,
  fcm_token: '',
  tour_completed: false,
  created_at: '2024-05-24T07:41:33.662Z',
  updated_at: '2024-05-24T07:41:33.662Z',
  deleted_at: null,
  socialAssets: '0',
  referral_code: '',
  fabricated: true
}

export const dashboardMenuData: {
  name: string
  url: string
  icon: any
  testid: string
  isLogout?: boolean
  iconPrepared?: boolean
}[] = [
  {
    name: 'Discover',
    url: '/dashboard/discover',
    icon: discover,
    testid: 'discover-link'
  },
  {
    name: 'Notifications',
    url: '/dashboard/notifications',
    icon: bell,
    testid: 'notifications-link'
  },
  {
    name: 'Settings',
    url: '/dashboard/settings/profile',
    icon: settings,
    testid: 'settings-link'
  },
  {
    name: 'Logout',
    url: '',
    icon: logout,
    testid: 'logout-link',
    isLogout: true
  }
]

export const isCypress: boolean = !!(window as any).Cypress

export const tourComponentProps: Partial<ReactourProps> = {
  accentColor: '#FF9502',
  closeWithMask: false,
  disableInteraction: true,
  showNavigationNumber: false,
  showNavigation: false,
  showCloseButton: false,
  showNumber: false,
  showButtons: false
}

export const tourComponentStyles = (isSmallScreen: boolean) => ({
  maxWidth: isSmallScreen ? '380px' : '534px',
  width: '100%',
  borderRadius: isSmallScreen ? 8 : 24,
  minHeight: isSmallScreen ? 135.82 : 285,
  paddingBottom: isSmallScreen ? 16 : 35,
  paddingTop: isSmallScreen ? 16 : 24,
  paddingLeft: isSmallScreen ? 16 : 24,
  paddingRight: isSmallScreen ? 16 : 24
})

export const appLinks = {
  ios: 'https://apps.apple.com/us/app/maoney-app/id6499010719?utm_source=maoney_website',
  android:
    'https://play.google.com/store/apps/details?id=com.maoney.mobile&pli=1?utm_source=maoney_website'
}

export const maoneyBlogLink = 'https://blog.maoney.com/blog'
