import apiConfig from '@config/apiConfig'
import { isCypress } from '@constants/app'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { updateNotifs } from '@store/slices/notifications'
import { useEffect } from 'react'
import { io } from 'socket.io-client'

const WS_URL = apiConfig.SocketUrl

const SocketHandler = () => {
  const { user } = useAppSelector(state => state.auth)

  const dispatch = useAppDispatch()

  const addNotification = (parsedMessage: any) => {
    dispatch(updateNotifs(parsedMessage))
    console.log('Websocket noted:', parsedMessage)
  }

  useEffect(() => {
    if (isCypress) {
      return
    }
    const socket = io(WS_URL)

    const handleSocketConnect = () => {
      console.log('Connected to WebSocket server')
    }

    const handleSocketNotification = async (message: any) => {
      const parsedMessage = JSON.parse(message.message)
      console.log('collapse', parsedMessage?.action_userId)
      if (
        Number(user?.id) !== Number(parsedMessage?.action_userId) &&
        Number(parsedMessage?.user_id) === Number(user?.id) &&
        parsedMessage?.topic !== 'shared_post'
      ) {
        addNotification(parsedMessage)
      }
    }

    const handleSocketError = (error: any) => {
      console.error('WebSocket error:', error)
    }

    const handleSocketDisconnect = () => {
      console.log('WebSocket connection closed')
    }

    // Event listeners
    socket.on('connect', handleSocketConnect)
    // socket.on('notification', handleSocketNotification)
    socket.onAny((eventName, message) => {
      if (eventName === 'WEB_SOCKET') {
        handleSocketNotification(message)
        console.log(`Received ${eventName} event:`, message)
      } else {
        console.log(`Received ${eventName} event:`, message)
      }
    })
    socket.on('error', handleSocketError)
    socket.on('disconnect', handleSocketDisconnect)

    // Cleanup function
    return () => {
      socket.off('connect', handleSocketConnect)
      socket.off('WEB_SOCKET', handleSocketNotification)
      socket.off('error', handleSocketError)
      socket.off('disconnect', handleSocketDisconnect)
      socket.close()
    }
  }, [])

  return null
}

export default SocketHandler
