const routes = {
  onboarding: '/onboarding',
  signup: '/signup',
  home: '/dashboard/home',
  purchasePoints: '/dashboard/points/purchase',
  discover: '/dashboard/discover',
  appRedirect: '/app-redirect'
}

export default routes
