import React from 'react'
import Skeleton from 'react-loading-skeleton'

const isDesktop = window.innerWidth > 1200
const isTablet = window.innerWidth >= 700 && window.innerWidth <= 1200
const isNotifs = window.location.pathname === '/dashboard/notifications'

const HeaderSkeleton = () => (
  <div className="flex justify-center items-center pt-[120px] xs:pt-[70px]">
    <nav
      className="bg-white  border-gray-200 
          px-2  fixed top-0 z-[99] h-[120px] xs:h-[70px]
       font-lato shadow-md drop-shadow-sm  w-full 
       flex justify-between items-center"
    >
      <div className="flex items-center justify-between w-full mr-10 xs:mr-6 max-w-7xl xl:mx-auto">
        <div
          className="flex align-middle
           justify-center items-center gap-10 mr-5"
        >
          <div className="flex items-center justify-start mx-10 xs:mx-6 xl:ml-0">
            <Skeleton height={40} width={40} circle />
          </div>
          {(isDesktop || isTablet) && (
            <div className="flex justify-between items-center align-middle rounded-xl">
              {/* <div className="w-3/4"> */}
              <div className="703px:block hidden">
                <Skeleton width={351} height={45} borderRadius={12} />
              </div>
            </div>
          )}
        </div>
        {isDesktop ? (
          <div
            className="flex items-center justify-start
             align-middle font-lato gap-14"
          >
            <div className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1">
              <Skeleton width={24} height={24} />
              <Skeleton height={18} width={40} />
            </div>

            <div className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1">
              <Skeleton height={30} width={30} circle />
              <Skeleton width={30} height={18} />
            </div>

            <div className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1">
              <Skeleton height={30} width={30} circle />
              <Skeleton width={30} height={18} />
            </div>

            <div className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1">
              {isNotifs ? (
                <>
                  <Skeleton width={24} height={24} circle />
                  <Skeleton width={40} height={18} />
                </>
              ) : (
                <>
                  <Skeleton width={24} height={24} circle />
                  <Skeleton width={40} height={18} />
                </>
              )}
            </div>
            <div className="bg-white w-64  flex justify-end items-center">
              <div
                className={`relative border-b-4 w-[125%] border-transparent`}
              >
                <div className="flex justify-end items-center align-middle space-x-3 cursor-pointer -mt-4">
                  <Skeleton height={22} width={100} />
                  <div>
                    <Skeleton circle height={48} width={48} />
                  </div>
                  <div>
                    <Skeleton height={10} width={15} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-end items-center">
            <Skeleton height={40} width={40} circle />

            <div
              className={`relative border-b-4  ml-4 w-[125%] border-transparent }`}
            >
              <Skeleton height={24} width={32} borderRadius={4} />
            </div>
          </div>
        )}
      </div>
    </nav>
  </div>
)

export default HeaderSkeleton
