import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { customBaseQuery } from './util'
import { UserType } from '../types/users'

export const usersService = createApi({
  reducerPath: 'usersService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getAllUsers: builder.query<
      { status: 'success'; users: UserType[] },
      { from: number; to: number }
    >({
      query: payload => apiConfig.User.GetUsers(payload.from, payload.to)
    })
  })
})

export const { useGetAllUsersQuery, useLazyGetAllUsersQuery } = usersService
