import { useAppSelector } from '@hooks/redux'
import { PropsWithChildren } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

const unprotectedRoutes = ['/', '/privacy-policy', '/blog', '/faqs', '/terms']

function UnProtected({ children }: PropsWithChildren) {
  const { jwt } = useAppSelector(state => state.auth)
  const location = useLocation()
  const [params] = useSearchParams()
  const nextPage = params.get('next')

  return jwt && !unprotectedRoutes.includes(location.pathname) ? (
    <Navigate to={nextPage || '/dashboard/home'} />
  ) : (
    children
  )
}
export default UnProtected
