import React from 'react'
import Skeleton from 'react-loading-skeleton'
import HeaderSkeleton from '../navigation/HeaderSkeleton'

const ManageSecuritySkeleton = () => (
  <div className="min-h-screen overflow-y-hidden">
    <HeaderSkeleton />
    <div className="flex overflow-hidden xs:flex-col xs:mt-[36px]">
      <div className="bg-white w-[248px] p-2 shrink-0 font-lato h-[calc(100vh-120px)] flex flex-col xs:w-auto xs:mx-4 xs:h-auto xs:rounded-lg xs:p-0">
        <div className="min-h-[64px] px-4 pt-8 xs:pt-4">
          <Skeleton
            width={100}
            borderRadius={8}
            className="xs:h-[20px] h-[32px]"
          />
        </div>
        <ul className="xs:flex w-full xs:space-x-5 mt-10 xs:px-4 ">
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
        </ul>
      </div>
      <div className="flex-1 p-10 overflow-y-auto bg-[#F1F1F1] max-h-[calc(100vh-120px)] xs:p-4 min-h-[180px]">
        <div className="bg-white py-6 px-10 xs:p-4 rounded-xl">
          <Skeleton height={30} width={'30%'} />
          <Skeleton className="h-5 xs:h-4 mt-2 xs:w-full w-[30%]" />
          <Skeleton className="mt-4 w-full" height={1} />
          {/* form */}
          <div className="mt-4 relative">
            <div className="lg:w-[555px] mx-auto xs:mx-0 xs:mt-20 md:mx-0 md:mt-20">
              <div className="mt-10 xs:mt-6">
                <div className="flex justify-evenly gap-4 w-full">
                  <div>
                    <Skeleton className="h-5 lg:h-7" width={100} />
                    <Skeleton height={2} />
                  </div>
                  <div>
                    <Skeleton className="h-5 lg:h-7" width={100} />
                    <Skeleton height={2} />
                  </div>
                </div>
                <div className="mt-10 w-full">
                  <div className="flex items-center justify-center">
                    <Skeleton className="w-52 xs:w-36 mx-auto h-52 xs:h-36" />
                  </div>
                  <div className="mt-5 xs:mt-4 flex items-center justify-center w-full">
                    <Skeleton width={200} height={20} />
                  </div>
                  <div className="flex flex-col gap-2 w-full mt-12 xs:mt-10">
                    <Skeleton width={100} height={20} />
                    <Skeleton height={56} borderRadius={12} />
                  </div>
                  <div className="mt-10 xs:mt-6">
                    <Skeleton height={54} borderRadius={12} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          ```
          {/* end form */}
        </div>
      </div>
    </div>
  </div>
)

export default ManageSecuritySkeleton
