import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '@hooks/redux'

function Protected({ children }: PropsWithChildren) {
  const { jwt } = useAppSelector(state => state.auth)
  const { pathname } = useLocation()

  return jwt ? children : <Navigate to={`/login?next=${pathname}`} />
}
export default Protected
