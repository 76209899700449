import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LogsState {
  lastConnectionsFetchTime: string | null
  lastUsersFetchTime: string | null
  canFetchConnections: boolean
  hasFetchedConnectionsAtLeastOnce: boolean
  hasFetchedUsersAtLeastOnce: boolean
  fetchingConnections: boolean
  fetchingUsers: boolean
}

const initialState: LogsState = {
  lastConnectionsFetchTime: null,
  lastUsersFetchTime: null,
  canFetchConnections: true,
  hasFetchedConnectionsAtLeastOnce: false,
  hasFetchedUsersAtLeastOnce: false,
  fetchingConnections: false,
  fetchingUsers: false
}
const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLastConnectionsFetchTime: state => {
      state.lastConnectionsFetchTime = new Date().toISOString()
      state.hasFetchedConnectionsAtLeastOnce = true
      state.fetchingConnections = false
    },
    setLastUsersFetchTime: state => {
      state.lastUsersFetchTime = new Date().toISOString()
      state.fetchingUsers = false
      state.hasFetchedUsersAtLeastOnce = true
    },
    enableFetchingConnectionsState: (state, action: PayloadAction<boolean>) => {
      state.canFetchConnections = action.payload
    },
    fetchConnState: (state, action: PayloadAction<boolean>) => {
      state.fetchingConnections = action.payload
    },
    fetchUsersState: (state, action: PayloadAction<boolean>) => {
      state.fetchingUsers = action.payload
    }
  }
})

const { reducer, actions } = logsSlice
export const {
  setLastConnectionsFetchTime,
  setLastUsersFetchTime,
  enableFetchingConnectionsState,
  fetchConnState,
  fetchUsersState
} = actions
export default reducer
