import ContentLoader, {
  Instagram,
  Facebook,
  List,
  BulletList
} from 'react-content-loader'
import { ReactComponent as SvgLoader } from '@assets/icons/loader.svg'

import loadingLogo from '@assets/images/logo-loading.gif'

import clsx from 'clsx'

interface LoaderProps {
  type?:
    | 'regular'
    | 'post'
    | 'wall'
    | 'sidebar'
    | 'network_circle'
    | 'list'
    | 'bullet'
    | 'circle'
    | 'profile'
    | 'chat-card'
  label?: string
  className?: string
  style?: object
  width?: number
  foregroundColor?: '#fff'
}

const Loader = ({
  type = 'regular',
  label,
  style,
  width = 150,
  foregroundColor = '#fff',
  className
}: LoaderProps) => {
  if (type === 'regular') {
    return (
      <center
        className={clsx(`w-[${width}px] mr-auto ml-auto`, className)}
        data-testid="regular-maoney-loader"
      >
        <img
          src={loadingLogo}
          className="w-[19px] h-[19px] mr-auto ml-auto"
          style={{ display: 'initial' }}
        />
        <span className="text-xs text-[#58534D]">
          &nbsp;&nbsp;&nbsp;&nbsp;
          {label}
        </span>
      </center>
    )
  } else if (type === 'profile') {
    return (
      <center>
        <Facebook foregroundColor={foregroundColor} style={style} />
      </center>
    )
  } else if (type === 'wall') {
    return (
      <center>
        <Instagram foregroundColor={foregroundColor} style={style} />
      </center>
    )
  } else if (type === 'list') {
    return (
      <center>
        <List foregroundColor={foregroundColor} style={style} />
      </center>
    )
  } else if (type === 'bullet') {
    return (
      <center>
        <BulletList foregroundColor={foregroundColor} style={style} />
      </center>
    )
  } else if (type === 'post') {
    return (
      <ContentLoader
        foregroundColor={foregroundColor}
        style={style}
        //height={140}
        speed={1}
        //backgroundColor={"#333"}
        //foregroundColor={"#999"}
        viewBox="0 0 280 70"
      >
        <rect x="0" y="0" rx="5" ry="5" width="90" height="90" />
        <rect x="100" y="17" rx="4" ry="4" width="80" height="6" />
        <rect x="100" y="35" rx="3" ry="3" width="250" height="7" />
        <rect x="100" y="50" rx="3" ry="3" width="250" height="7" />
      </ContentLoader>
    )
  } else if (type === 'chat-card') {
    return (
      <ContentLoader
        foregroundColor={foregroundColor}
        style={style}
        //height={140}
        speed={1}
        //backgroundColor={"#333"}
        //foregroundColor={"#999"}
        viewBox="0 0 280 50"
      >
        <rect x="0" y="0" rx="20" ry="20" width="40" height="40" />
        <rect x="50" y="7" rx="4" ry="4" width="80" height="6" />
        <rect x="250" y="10" rx="3" ry="3" width="20" height="10" />
        <rect x="50" y="28" rx="3" ry="3" width="250" height="8" />
      </ContentLoader>
    )
  } else if (type === 'circle') {
    return (
      <ContentLoader
        foregroundColor={foregroundColor}
        style={style}
        //height={140}
        speed={1}
        //backgroundColor={"#333"}
        //foregroundColor={"#999"}
        viewBox="0 0 60 80"
      >
        <rect x="0" y="0" rx="150" ry="150" width="40" height="43" />
      </ContentLoader>
    )
  }
}

/** Loader that covers the entire screen */
export const BlockingLoader = ({
  loading,
  className
}: {
  loading?: boolean
  className?: string
}) => {
  if (!loading) {
    return null
  }
  return (
    <div className="h-screen w-full fixed top-0 left-0 flex items-center justify-center bg-black/[.5]">
      <SvgLoader
        className={clsx('!fill-primary w-5 h-5 animate-spin', className)}
      />
    </div>
  )
}

export default Loader
