import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './util'
import { UserRecommendation } from '@typs/recommendations'

export interface RecommendationsFilterParams {
  userId: number
  nationality?: string
  state?: string
  city?: string
  country?: string
  page: number
  limit: number
}

export const recommendationsService = createApi({
  reducerPath: 'recommendationsService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getRecommendations: builder.query<
      UserRecommendation[],
      RecommendationsFilterParams
    >({
      query: ({ userId, page, limit, ...rest }) => ({
        url: `/api/recommendations/users/${userId}`,
        params: {
          low_limit: (page - 1) * limit,
          upper_limit: page * limit,
          ...rest
        }
      })
    })
  })
})

export const { useGetRecommendationsQuery, useLazyGetRecommendationsQuery } =
  recommendationsService
