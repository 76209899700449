import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { useEditProfileMutation } from '@services/profile-service'
import { authenticateUser } from '@store/slices/auth'
import { EditProfileValues } from '@typs/profile'
import { getProfileImageUrl } from '@utils/helpers'
import { useState } from 'react'

interface EditProfileFunctionArgs {
  values: EditProfileValues
  onSuccessCallback?: VoidFunction
  onFailCallback?: VoidFunction
}

const useEditProfile = () => {
  const [loading, setLoading] = useState(false)
  const { user } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()
  const [updateProfile] = useEditProfileMutation()

  const editProfile = async ({
    values,
    onSuccessCallback,
    onFailCallback
  }: EditProfileFunctionArgs) => {
    setLoading(true)
    try {
      const formData = new FormData()
      const { image, ...rest } = values
      Object.keys(rest).map(key => {
        const typedKey = key as keyof Omit<EditProfileValues, 'image'>
        const value = rest[typedKey]
        if (value) {
          formData.append(key, value.toString())
        }
      })
      if (image) {
        formData.append('picture', image)
      }
      formData.append(
        'user',
        JSON.stringify({
          id: user!.id
        })
      )
      const data = await updateProfile(formData).unwrap()
      const newData = {
        ...data,
        picture: getProfileImageUrl(data?.picture)
      }
      dispatch(authenticateUser({ user: newData }))
      setLoading(false)
      onSuccessCallback && onSuccessCallback()
    } catch (e) {
      setLoading(false)
      onFailCallback && onFailCallback()
    }
  }

  return {
    editingProfile: loading,
    editProfile
  }
}

export default useEditProfile
