import { createSlice } from '@reduxjs/toolkit'

const initialState: any = []

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogs: (_state, { payload }) => {
      return payload
    },
    clearBlogs: () => {
      return []
    }
  }
})

const { reducer, actions } = blogSlice
export const { setBlogs, clearBlogs } = actions
export default reducer
