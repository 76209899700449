import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TopTenSocialSkeleton = () => (
  <div className="w-full 1201px:min-w-[380px] h-[min-content]">
    <div className="flex flex-col gap-5 w-full">
      <div className="rounded-xl w-full h-full bg-[#ffffff]">
        <div className="flex flex-col w-full justify-center items-center py-10 text-center">
          <Skeleton height={32} width={'90%'} containerClassName="w-full" />
          <div className="w-full">
            <Skeleton height={48} width={'90%'} />
            <Skeleton height={48} width={'30%'} />
          </div>
        </div>
        <div>
          <div className="max-w-3xl mx-auto font-lato">
            <div className="flex flex-col px-2 pb-6">
              <div className="overflow-x-auto rounded-xl border border-[#928B8080]">
                <div className="inline-block min-w-full align-middle">
                  <div style={{ overflowX: 'hidden' }} className="">
                    <table
                      style={{ overflowX: 'hidden' }}
                      className="min-w-full divide-y divide-[#928B8080] table-fixed first:divide-none"
                    >
                      <thead className="bg-[#928B801A] text-left">
                        <tr>
                          <th
                            style={{
                              width: '30%'
                            }}
                            scope="col"
                            className="py-4 px-4 font-lato text-[#140C00]"
                          >
                            <Skeleton width={'100%'} height={20} />
                          </th>
                          <th
                            style={{
                              width: '30%'
                            }}
                            scope="col"
                            className="py-4 px-4 font-lato text-[#140C00] whitespace-nowrap md:block lg:block"
                          >
                            <Skeleton width={'100%'} height={20} />
                          </th>
                          <th
                            className="py-4 px-4"
                            style={{
                              width: '30%'
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#928B8080]">
                        {[...Array(10)].map((_, index) => (
                          <tr className="" key={`${index}`}>
                            <td style={{ width: '30%' }} className="px-2">
                              <div className="flex items-center justify-start gap-3 py-4">
                                <Skeleton
                                  circle={true}
                                  width={50}
                                  height={50}
                                />
                                <div>
                                  <div className="text-[#58534D]">
                                    <Skeleton variant="text" width={50} />
                                  </div>
                                  <Skeleton variant="text" width={80} />
                                </div>
                              </div>
                            </td>
                            <td
                              style={{ width: '30%' }}
                              className="text-center text-[#58534D] whitespace-normal md:table-cell lg:table-cell align-middle"
                            >
                              <Skeleton variant="text" width={20} />
                            </td>
                            <td style={{ width: '30%', textAlign: 'center' }}>
                              <Skeleton variant="text" width={20} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TopTenSocialSkeleton
