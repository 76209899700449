import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import {
  ConnectionCategoryType,
  GetConnectionsResponse,
  RequestConnectionPayload,
  RequestConnectionResponse
} from '../types/connections'
import { Prettify } from '../types/shared'
import { POST, customBaseQuery } from './util'

export const connectionsService = createApi({
  reducerPath: 'connectionsService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getUserConnections: builder.query<
      Prettify<GetConnectionsResponse>,
      { userId: number; from?: number; to?: number }
    >({
      query: query => {
        const { userId, from = 0, to = 20 } = query || {}
        return {
          url: `${apiConfig.User.GetSocialAssets}/${userId}/${from}/${to}`
        }
      }
    }),
    requestForConnection: builder.mutation<
      RequestConnectionResponse,
      RequestConnectionPayload
    >({
      query: body => ({
        url: apiConfig.User.RequestForConnection,
        method: POST,
        body
      })
    }),
    disconnectAsset: builder.mutation({
      query: body => ({
        url: apiConfig.Networks.DisconnectAsset,
        method: POST,
        body
      })
    }),
    updateCategory: builder.mutation<
      any,
      { connection_id: number; user_id: number; categorization: string }
    >({
      query: body => ({
        url: `${apiConfig.User.CategorizeAssets}`,
        method: POST,
        body
      })
    }),
    updateConnectStatus: builder.mutation({
      query: body => ({
        url: `${apiConfig.User.UpdateAssets}`,
        method: POST,
        body
      })
    }),
    getUserNetworks: builder.query({
      query: myId => ({
        url: `${apiConfig.Networks.UserNetworks}?loggedInUser=${myId}`
      })
    }),
    muteAsset: builder.mutation({
      query: body => ({
        url: `${apiConfig.Networks.MuteAsset}`,
        method: POST,
        body
      })
    }),
    unmuteAsset: builder.mutation({
      query: body => ({
        url: `${apiConfig.Networks.UnmuteAsset}`,
        method: POST,
        body
      })
    }),
    rejectUserConnectionRequest: builder.mutation({
      query: requestId => ({
        url: `${apiConfig.Networks.UserNetworkRequests}/${requestId}`,
        method: 'DELETE'
      })
    }),
    getConnectionCategories: builder.query<
      { categories: ConnectionCategoryType[] },
      void
    >({
      keepUnusedDataFor: 3600,
      query: () => apiConfig.Networks.GetConnectionCategories
    })
  })
})

export const {
  useGetUserConnectionsQuery,
  useLazyGetUserConnectionsQuery,
  useRequestForConnectionMutation,
  useDisconnectAssetMutation,
  useUpdateCategoryMutation,
  useUpdateConnectStatusMutation,
  useGetUserNetworksQuery,
  useMuteAssetMutation,
  useUnmuteAssetMutation,
  useRejectUserConnectionRequestMutation,
  useGetConnectionCategoriesQuery,
} = connectionsService
