import AddCountryOfOriginModal from '@components/Auth/profile/AddCountryOfOriginModal'
import { Outlet } from 'react-router-dom'

function Dashboard() {
  return (
    <div className="h-[100vh] w-full flex flex-col">
      <AddCountryOfOriginModal />
      <div className="flex-grow h-full">
        <Outlet />
      </div>
    </div>
  )
}

export default Dashboard
