import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProfileSummarySkeleton from './ProfileSummarySkeleton'
import PostListSkeleton from './PostListSkeleton'
import SocialAssetListSkeleton from './SocialAssetListSkeleton'
import TopTenSocialSkeleton from './TopTenSocialSkeleton'
import HeaderSkeleton from '../navigation/HeaderSkeleton'

const DashboardSkeleton = () => (
  <div className="h-screen overflow-y-hidden">
    <div className=" max-w-[1320px] mx-auto h-full">
      {/* mobile */}
      <div className="h-full flex flex-col md-full:hidden">
        <HeaderSkeleton />
        <div className="pt-7 px-5 flex flex-col flex-1">
          <div className="flex items-center mb-5 w-full justify-between space-x-5">
            <Skeleton height={42} className="rounded-[99px] min-w-[100px]" />
            <Skeleton height={42} className="rounded-[99px] min-w-[100px]" />
            <Skeleton height={42} className="rounded-[99px] min-w-[100px]" />
          </div>
          <div className="mt-5 flex flex-col flex-1 space-y-5">
            <ProfileSummarySkeleton />
            <SocialAssetListSkeleton />
          </div>
        </div>
      </div>
      {/* at 761px */}
      <div className="h-full hidden md-full:flex 1201px:hidden flex-col w-full">
        <HeaderSkeleton />
        <div className="flex-1 py-7 px-6 flex gap-10">
          <div className="w-[320px] flex flex-col space-y-5 h-full flex-shrink-0">
            <ProfileSummarySkeleton />
            <SocialAssetListSkeleton />
            <TopTenSocialSkeleton />
          </div>
          <div className="flex-1">
            <PostListSkeleton />
          </div>
        </div>
      </div>
      {/* at 1201px */}
      <div className="h-full hidden 1201px:flex flex-col w-full">
        <HeaderSkeleton />

        <div className="flex-1 py-7 xs:px-[64px] flex gap-8">
          {/* left */}
          <div className="flex flex-col gap-8 w-[315px] flex-shrink-0 h-[min-content]">
            <ProfileSummarySkeleton />
            <SocialAssetListSkeleton />
          </div>
          {/* middle */}
          <div className="flex flex-col w-full overflow-y-auto">
            <PostListSkeleton />
          </div>
          {/* right */}
          <div className="w-[378px] h-[min-content] flex-shrink-0">
            <TopTenSocialSkeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default DashboardSkeleton
