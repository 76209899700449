import { PrimaryButton } from '@components/Buttons'
import Select from '@components/Forms/Select'
import { countryOptions } from '@constants/countries'
import useEditProfile from '@hooks/profile/useEditProfile'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { Modal } from '@mui/material'
import { updateModalMenu } from '@store/slices/app'
import { OptionType } from '@typs/app'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

const AddCountryOfOriginModal = () => {
  const { modalMenu } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const { editProfile, editingProfile } = useEditProfile()

  const onClose = () => dispatch(updateModalMenu(null))
  const { control, handleSubmit } = useForm<{ country: OptionType }>()

  const onSubmit = (values: { country: OptionType }) => {
    editProfile({
      values: { country_origin: values.country.value },
      onSuccessCallback: () => {
        toast.success('Updated successfully!')
        onClose()
      }
    })
  }

  return (
    <Modal open={modalMenu === 'Country Of Origin'} onClose={onClose}>
      <div className="flex items-center justify-center h-screen w-screen">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rounded-2xl bg-white font-lato lg:w-[500px] max-w-md py-8 px-6 xs:py-4 xs:px-4 mx-5"
        >
          <p className="text-[#140C00] font-lato text-center font-bold text-[24px] xs:text-[20px] mt-2">
            Update Your Country of Origin
          </p>
          <div className="text-[#58534D] mt-2 max-w-[400px] text-center mx-auto mb-5">
            To ensure we provide you with the most relevant information and
            services, please add your country of origin in your profile.
          </div>
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                label="Country of Origin"
                placeholder="United States"
                options={countryOptions}
                value={value?.value ? value : null}
                onChange={onChange}
                menuPlacement="top"
                ref_={ref}
                required
                inputId="country_of_origin"
                formatOptionLabel={option => (
                  <div className="flex items-center space-x-1">
                    <img
                      alt={option.label}
                      loading="lazy"
                      className="h-6 w-6 flex-shrink-0"
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.code}.svg`}
                    />
                    <span className="text-base text-_140C00">
                      {option.label}
                    </span>
                  </div>
                )}
              />
            )}
          />
          <div className="mt-5">
            <PrimaryButton
              text="Update"
              loading={editingProfile}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddCountryOfOriginModal
