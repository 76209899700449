import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ConnectionStatus,
  ConnectionType,
  PopulatedConnection
} from 'types/connections'
import { ConnectionsState } from '@typs/store'

const initialState: ConnectionsState = {
  connections: [],
  topTenSocial: [],
  connectionRequestToCancel: { visible: false, populatedConnection: null }
}

/** Reducer to store the connections of the currently logged in user */
const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setConnections: (state, action: PayloadAction<ConnectionType[]>) => {
      state.connections = action.payload
    },
    setMoreConnections: (state, action: PayloadAction<ConnectionType[]>) => {
      state.connections = [...state.connections, ...action.payload]
    },
    setTopTenSocial: (
      state,
      action: PayloadAction<ConnectionsState['topTenSocial']>
    ) => {
      state.topTenSocial = action.payload
    },
    setConnectionRequestToCancel: (
      state,
      action: PayloadAction<null | PopulatedConnection>
    ) => {
      state.connectionRequestToCancel = {
        visible: true,
        populatedConnection: action.payload
      }
    },
    clearConnectionRequestToCancel: state => {
      state.connectionRequestToCancel = {
        visible: false,
        populatedConnection: state.connectionRequestToCancel.populatedConnection
      }
    },
    invalidateConnection: (state, action: PayloadAction<number>) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, invalid: true }
        }
        return conn
      })
      state.connections = newConnections
    },
    reValidateConnection: (state, action: PayloadAction<number>) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, invalid: false }
        }
        return conn
      })
      state.connections = newConnections
    },
    removeConnection: (state, action: PayloadAction<number>) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].filter(
        conn => conn.id !== connectionId
      )
      state.connections = newConnections
    },
    editConnectionMuteStatus: (
      state,
      action: PayloadAction<{ connectionId: number; newState: boolean }>
    ) => {
      const { connectionId, newState } = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, muted: newState }
        }
        return conn
      })
      state.connections = newConnections
    },
    editConnectionStatus: (
      state,
      action: PayloadAction<{
        connectionId: number
        newState: ConnectionStatus
      }>
    ) => {
      const { connectionId, newState } = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, status: newState }
        }
        return conn
      })
      state.connections = newConnections
    },
    insertConnection: (state, action: PayloadAction<ConnectionType>) => {
      state.connections = [...state.connections, action.payload]
    },
    replaceFakeConnectionWithRealConnetion: (
      state,
      action: PayloadAction<{ id: number; data: ConnectionType }>
    ) => {
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === action.payload.id) {
          return action.payload.data
        }
        return conn
      })
      state.connections = newConnections
    },
    editConnectionCategory: (
      state,
      action: PayloadAction<{
        connectionId: number
        currentUserId: number
        newCategory: any
      }>
    ) => {
      const { connectionId, newCategory, currentUserId } = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          if (conn.sender_id === currentUserId) {
            return { ...conn, sender_categorization: newCategory }
          } else if (conn.receiver_id === currentUserId) {
            return { ...conn, receiver_categorization: newCategory }
          }
        }
        return conn
      })
      state.connections = newConnections
    }
  }
})

export const {
  setConnections,
  setTopTenSocial,
  setConnectionRequestToCancel,
  clearConnectionRequestToCancel,
  invalidateConnection,
  reValidateConnection,
  removeConnection,
  editConnectionMuteStatus,
  editConnectionStatus,
  insertConnection,
  editConnectionCategory,
  setMoreConnections,
  replaceFakeConnectionWithRealConnetion
} = connectionsSlice.actions

export default connectionsSlice.reducer
