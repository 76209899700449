import React from 'react'
import Skeleton from 'react-loading-skeleton'
import HeaderSkeleton from '../navigation/HeaderSkeleton'
import PaginationSkeleton from '../navigation/PaginationSkeleton'
import PostListSkeleton from '../home/PostListSkeleton'

const ProfilePageSkeleton = () => (
  <div className="h-screen overflow-y-hidden">
    <HeaderSkeleton />
    <div className="bg-[#F1F1F1] w-full h-full xs:pt-0">
      <div className="px-0 mb-10">
        <div className="flex xs:flex-col md:flex-col gap-10 xs:gap-6 max-w-7xl xl:mx-auto mx-16 xs:mx-6 pb-4">
          <div className="xs:w-full md:w-full w-[56%] flex flex-col justify-start items-center mt-12 gap-10 xs:gap-6 h-[min-content]">
            {/* profile card */}
            <div className="rounded-xl w-full bg-[#ffffff]">
              <div className="flex flex-col justify-between align-middle items-center  text-center h-full">
                <div className="bg-white rounded-t-xl drop-shadow-[0_10px_20px_rgba(0,0,0,0.08)] w-full h-36 xs:h-24">
                  <div className="self-start p-10 xs:p-4 mt-8 pb-1 w-full justify-between hidden">
                    <div className="">
                      <div className="">
                        <div
                          className="rounded-full    border-gray-200
                overflow-hidden 
                flex justify-between z-10  w-[70px] mt-[40px] xs:mt-0"
                        >
                          <div className="w-full cursor-pointer relative flex-shrink-0">
                            <div className="flex justify-start items-center w-full  my-0 font-lato gap-2 ">
                              <div className="relative"></div>
                              <div className="flex flex-col justify-start items-start">
                                <div className="w-full">
                                  <p className="text-[#58534D] text-sm xs:text-xs">
                                    {' '}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-b-xl w-full p-10 xs:p-4 pt-0 relative">
                  <div className="mt-2 md-full:hidden">
                    <div className="text-left">
                      <Skeleton width={250} height={20} />
                    </div>
                    <div className="text-left mt-2">
                      <Skeleton width={200} height={20} />
                    </div>
                    <div className="flex items-center gap-4 mt-4 flex-wrap">
                      <div className="flex items-center gap-2">
                        <Skeleton width={20} height={20} circle />
                        <Skeleton
                          className="h-[16px] xs:h-sm"
                          width={100}
                          height={14}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <Skeleton width={14} height={18} borderRadius={4} />
                        <Skeleton
                          className="h-[14px] xs:h-[12px]"
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="absolute left-4 top-[-25px] bg-white p-1 z-[3] rounded-[100px] md-full:left-[40px] md-full:top-[-85px]">
                    <Skeleton
                      className="h-[50px] w-[50px] md-full:h-[125px] md-full:w-[125px]"
                      circle
                    />
                  </div>
                  <div className="flex-row mt-[60px] justify-between hidden md-full:flex">
                    <div className="mt-15" style={{ flex: '0.85 1 0%;' }}>
                      <Skeleton
                        className="h-[1.5rem] xs:h-[base] mx-8"
                        width={100}
                      />
                      <Skeleton className="xs:h-[14px] h-4" width={50} />
                    </div>
                    <div className="flex items-center gap-1">
                      <Skeleton width={1} height={40} className="mr-10" />
                      <div className="flex flex-col gap-4 xs:flex-row xs:gap-6 shrink-0">
                        <div className="flex items-center gap-2">
                          <Skeleton height={20} width={20} />
                          <Skeleton
                            className="h-[14px] xs:h-[12px]"
                            width={70}
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <Skeleton width={14} height={14} circle />
                          <Skeleton
                            className="h-[14px] xs:text-[12px]"
                            width={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* your posts */}
            <div className="rounded-xl w-full bg-[#ffffff] p-10 xs:p-4">
              <div className="flex justify-between items-center mb-4 xs:mb-8">
                <Skeleton height={32} width={100} />
                <Skeleton height={32} width={98.55} borderRadius={8} />
              </div>
              <div className="w-full pb-4 last:pb-0 border-b border-[rgba(146,139,128,0.4)] last:border-b-0 mb-4 last:mb-0 hover:bg-gray-50 cursor-pointer">
                <Skeleton height={24} width={250} />
                <div className="flex items-center gap-4 mt-5 xs:mt-3">
                  <div className="shrink-0">
                    <Skeleton height={72} width={100} borderRadius={6} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Skeleton className="h-4 xs:h-[14px]" width={100} />
                    <div>
                      <Skeleton className="h-[14px] xs:h-3" width={150} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pb-4 last:pb-0 border-b border-[rgba(146,139,128,0.4)] last:border-b-0 mb-4 last:mb-0 hover:bg-gray-50 cursor-pointer">
                <Skeleton height={24} width={250} />
                <div className="flex items-center gap-4 mt-5 xs:mt-3">
                  <div className="shrink-0">
                    <Skeleton height={72} width={100} borderRadius={6} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Skeleton className="h-4 xs:h-[14px]" width={100} />
                    <div>
                      <Skeleton className="h-[14px] xs:h-3" width={150} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* social assets management */}
            <div className="rounded-xl w-full bg-[#ffffff] p-10 xs:p-4">
              <div className="w-full flex flex-col justify-between">
                <div className="flex justify-between items-center mt-8 gap-4">
                  <Skeleton height={24} width={200} borderRadius={12} />
                  <Skeleton
                    height={38}
                    className="w-[100px] md-full:w-[200px]"
                    borderRadius={8}
                  />
                </div>
                <table className="mt-4">
                  <tbody className="bg-white divide-y divide-[#928B8080]">
                    <tr className="grid grid-cols-2">
                      <td>
                        <div className="flex items-center">
                          <div className="w-full relative flex-shrink-0 cursor-pointer">
                            <div className="flex justify-start items-center w-full  my-4 font-lato gap-2 ">
                              <Skeleton height={50} width={50} circle />
                              <div className="flex flex-col justify-start items-start">
                                <div>
                                  <Skeleton height={16} width={150} />
                                </div>
                                <div className="w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 flex items-center justify-end text-sm font-medium text-gray-900 h-full">
                        <div className="flex justify-center items-center align-middle gap-10">
                          <div className="flex  justify-center items-center align-middle gap-3">
                            <div className="w-full justify-center items-center gap-6 hidden md:flex lg:flex">
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full float-right justify-center items-center">
                            <td className="text-sm font-medium text-gray-900 flex justify-end pr-6">
                              <div className="hidden md:block lg:block">
                                <div className="relative mt-1">
                                  <button
                                    className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                    id="headlessui-listbox-button-:r3:"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                  >
                                    <Skeleton height={21} width={27} circle />

                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <Skeleton height={8} width={11} circle />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="md:hidden lg:hidden flex items-center gap-[2px]">
                                <div className="flex items-center">
                                  <div className="relative mt-1">
                                    <button
                                      className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                      id="headlessui-listbox-button-:r4:"
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded="false"
                                      data-headlessui-state=""
                                    >
                                      <Skeleton height={21} width={27} circle />
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <Skeleton
                                          height={8}
                                          width={11}
                                          circle
                                        />
                                      </span>
                                    </button>
                                  </div>
                                  <Skeleton height={30} width={30} circle />
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="grid grid-cols-2">
                      <td>
                        <div className="flex items-center">
                          <div className="w-full relative flex-shrink-0 cursor-pointer">
                            <div className="flex justify-start items-center w-full  my-4 font-lato gap-2 ">
                              <Skeleton height={50} width={50} circle />
                              <div className="flex flex-col justify-start items-start">
                                <div>
                                  <Skeleton height={16} width={150} />
                                </div>
                                <div className="w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 flex items-center justify-end text-sm font-medium text-gray-900 h-full">
                        <div className="flex justify-center items-center align-middle gap-10">
                          <div className="flex  justify-center items-center align-middle gap-3">
                            <div className="w-full justify-center items-center gap-6 hidden md:flex lg:flex">
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full float-right justify-center items-center">
                            <td className="text-sm font-medium text-gray-900 flex justify-end pr-6">
                              <div className="hidden md:block lg:block">
                                <div className="relative mt-1">
                                  <button
                                    className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                    id="headlessui-listbox-button-:r3:"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                  >
                                    <Skeleton height={21} width={27} circle />

                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <Skeleton height={8} width={11} circle />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="md:hidden lg:hidden flex items-center gap-[2px]">
                                <div className="flex items-center">
                                  <div className="relative mt-1">
                                    <button
                                      className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                      id="headlessui-listbox-button-:r4:"
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded="false"
                                      data-headlessui-state=""
                                    >
                                      <Skeleton height={21} width={27} circle />
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <Skeleton
                                          height={8}
                                          width={11}
                                          circle
                                        />
                                      </span>
                                    </button>
                                  </div>
                                  <Skeleton height={30} width={30} circle />
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="grid grid-cols-2">
                      <td>
                        <div className="flex items-center">
                          <div className="w-full relative flex-shrink-0 cursor-pointer">
                            <div className="flex justify-start items-center w-full  my-4 font-lato gap-2 ">
                              <Skeleton height={50} width={50} circle />
                              <div className="flex flex-col justify-start items-start">
                                <div>
                                  <Skeleton height={16} width={150} />
                                </div>
                                <div className="w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 flex items-center justify-end text-sm font-medium text-gray-900 h-full">
                        <div className="flex justify-center items-center align-middle gap-10">
                          <div className="flex  justify-center items-center align-middle gap-3">
                            <div className="w-full justify-center items-center gap-6 hidden md:flex lg:flex">
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full float-right justify-center items-center">
                            <td className="text-sm font-medium text-gray-900 flex justify-end pr-6">
                              <div className="hidden md:block lg:block">
                                <div className="relative mt-1">
                                  <button
                                    className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                    id="headlessui-listbox-button-:r3:"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                  >
                                    <Skeleton height={21} width={27} circle />

                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <Skeleton height={8} width={11} circle />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="md:hidden lg:hidden flex items-center gap-[2px]">
                                <div className="flex items-center">
                                  <div className="relative mt-1">
                                    <button
                                      className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                      id="headlessui-listbox-button-:r4:"
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded="false"
                                      data-headlessui-state=""
                                    >
                                      <Skeleton height={21} width={27} circle />
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <Skeleton
                                          height={8}
                                          width={11}
                                          circle
                                        />
                                      </span>
                                    </button>
                                  </div>
                                  <Skeleton height={30} width={30} circle />
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="grid grid-cols-2">
                      <td>
                        <div className="flex items-center">
                          <div className="w-full relative flex-shrink-0 cursor-pointer">
                            <div className="flex justify-start items-center w-full  my-4 font-lato gap-2 ">
                              <Skeleton height={50} width={50} circle />
                              <div className="flex flex-col justify-start items-start">
                                <div>
                                  <Skeleton height={16} width={150} />
                                </div>
                                <div className="w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 flex items-center justify-end text-sm font-medium text-gray-900 h-full">
                        <div className="flex justify-center items-center align-middle gap-10">
                          <div className="flex  justify-center items-center align-middle gap-3">
                            <div className="w-full justify-center items-center gap-6 hidden md:flex lg:flex">
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                              <div>
                                <div className="cursor-pointer">
                                  <Skeleton height={24} width={24} circle />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full float-right justify-center items-center">
                            <td className="text-sm font-medium text-gray-900 flex justify-end pr-6">
                              <div className="hidden md:block lg:block">
                                <div className="relative mt-1">
                                  <button
                                    className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                    id="headlessui-listbox-button-:r3:"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                  >
                                    <Skeleton height={21} width={27} circle />

                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <Skeleton height={8} width={11} circle />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="md:hidden lg:hidden flex items-center gap-[2px]">
                                <div className="flex items-center">
                                  <div className="relative mt-1">
                                    <button
                                      className="relative w-20 cursor-pointer bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                      id="headlessui-listbox-button-:r4:"
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded="false"
                                      data-headlessui-state=""
                                    >
                                      <Skeleton height={21} width={27} circle />
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <Skeleton
                                          height={8}
                                          width={11}
                                          circle
                                        />
                                      </span>
                                    </button>
                                  </div>
                                  <Skeleton height={30} width={30} circle />
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PaginationSkeleton />
              </div>
            </div>
          </div>
          <div className="xs:w-full md:w-full w-[41%] gap-10 xs:gap-6 mt-12 xs:mt-0 h-[inherit] pb-10 overflow-hidden scrollbar-none min-h-[800px] md:!min-h-auto">
            <div className="overflow-y-auto scrollbar-none min-h-[800px]">
              <PostListSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ProfilePageSkeleton
