import { useAppSelector } from '@hooks/redux'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const ErrorFallback = ({ error }: FallbackProps) => {
  const navigate = useNavigate()

  const { user } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (error) {
      if (user) {
        navigate('/dashboard/home')
      } else {
        navigate('/')
      }
    }
    toast.error('Something went wrong, please try again later')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return <div></div>
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
}

export default ErrorFallback
