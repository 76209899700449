import clsx from 'clsx'
import Skeleton from 'react-loading-skeleton'

const PostListSkeleton = () =>
  Array.from({ length: 8 }).map((_, index) => (
    <div
      key={index}
      style={{
        justifyContent: 'center'
      }}
    >
      <div
        className={clsx(
          'font-lato rounded-xl w-full bg-[#ffffff] mt-10 xs:mt-8 overflow-y-auto',
          { '!mt-0': index === 0 }
        )}
      >
        <div className="px-4 py-0 xs:py-4 pb-3">
          <div className="flex items-start flex-row">
            <div
              style={{ gap: '20px', paddingTop: '20px' }}
              className="justify-start flex items-start w-full"
            >
              <Skeleton circle={true} width={50} height={50} />
              <div>
                <Skeleton width={60} height={15} />
                <Skeleton width={150} />
              </div>
            </div>
          </div>

          <div style={{ width: '100%' }} className="mt-5 xs:mt-4">
            <div style={{ width: '70%' }} className="mt-5 xs:mt-4">
              <Skeleton count={2} />
            </div>

            <div style={{ width: '100%' }} className="mt-5 xs:mt-4">
              <Skeleton height={250} />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
            className="mt-5 xs:mt-4"
          >
            <Skeleton width={60} />
            <Skeleton width={100} />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
            className="mt-5 xs:mt-4"
          >
            <Skeleton width={80} />
            <Skeleton width={80} />
            <Skeleton width={80} />
          </div>
        </div>
      </div>
    </div>
  ))

export default PostListSkeleton
