import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  connectionRequest: {
    receiveAll: true,
    requestReceived: {
      inApp: true,
      push: true,
      email: true
    },
    requestAccepted: {
      inApp: true,
      push: true,
      email: true
    }
  },
  post: {
    inApp: true,
    push: true,
    email: true
  },
  commentsAndLikes: {
    inApp: true,
    push: true,
    email: true
  },
  postNotificationRecipients: {
    allSocialAssets: true,
    friends: true,
    family: true,
    colleagues: true,
    others: true
  }
}

const notifSlice = createSlice({
  name: 'notif',
  initialState,
  reducers: {
    setInitialState(_state, action) {
      return action.payload
    },
    setConnectionRequestReceiveAll(state, action) {
      state.connectionRequest.receiveAll = action.payload
    },
    setConnectionRequestReceived(state, action) {
      state.connectionRequest.requestReceived = {
        ...state.connectionRequest.requestReceived,
        ...action.payload
      }
    },
    setConnectionRequestAccepted(state, action) {
      state.connectionRequest.requestAccepted = {
        ...state.connectionRequest.requestAccepted,
        ...action.payload
      }
    },
    setPostNotifications(state, action) {
      state.post = {
        ...state.post,
        ...action.payload
      }
    },
    setCommentsAndLikesNotifications(state, action) {
      state.commentsAndLikes = {
        ...state.commentsAndLikes,
        ...action.payload
      }
    },
    setPostNotificationRecipients(state, action) {
      state.postNotificationRecipients = {
        ...state.postNotificationRecipients,
        ...action.payload
      }
    },
    setRequestReceivedInApp(state, action) {
      state.connectionRequest.requestReceived.inApp = action.payload
    },
    setRequestReceivedPush(state, action) {
      state.connectionRequest.requestReceived.push = action.payload
    },
    setRequestReceivedEmail(state, action) {
      state.connectionRequest.requestReceived.email = action.payload
    },
    setRequestAcceptedInApp(state, action) {
      state.connectionRequest.requestAccepted.inApp = action.payload
    },
    setRequestAcceptedPush(state, action) {
      state.connectionRequest.requestAccepted.push = action.payload
    },
    setRequestAcceptedEmail(state, action) {
      state.connectionRequest.requestAccepted.email = action.payload
    },
    setPostInApp(state, action) {
      state.post.inApp = action.payload
    },
    setPostPush(state, action) {
      state.post.push = action.payload
    },
    setPostEmail(state, action) {
      state.post.email = action.payload
    },
    setCommentsAndLikesInApp(state, action) {
      state.commentsAndLikes.inApp = action.payload
    },
    setCommentsAndLikesPush(state, action) {
      state.commentsAndLikes.push = action.payload
    },
    setCommentsAndLikesEmail(state, action) {
      state.commentsAndLikes.email = action.payload
    },
    setAllPostNotificationRecipientsTrue(state) {
      state.postNotificationRecipients.allSocialAssets = true
      state.postNotificationRecipients.friends = true
      state.postNotificationRecipients.family = true
      state.postNotificationRecipients.colleagues = true
      state.postNotificationRecipients.others = true
    },
    setAllPostNotificationRecipientsFalse(state) {
      state.postNotificationRecipients.allSocialAssets = false
      state.postNotificationRecipients.friends = false
      state.postNotificationRecipients.family = false
      state.postNotificationRecipients.colleagues = false
      state.postNotificationRecipients.others = false
    },
    clearNotifications() {
      return initialState
    },
    setAllConnectionRequestNotificationsTrue(state) {
      state.connectionRequest.requestReceived.inApp = true
      state.connectionRequest.requestReceived.push = true
      state.connectionRequest.requestReceived.email = true
      state.connectionRequest.requestAccepted.inApp = true
      state.connectionRequest.requestAccepted.push = true
      state.connectionRequest.requestAccepted.email = true
    },
    setAllConnectionRequestNotificationsFalse(state) {
      state.connectionRequest.requestReceived.inApp = false
      state.connectionRequest.requestReceived.push = false
      state.connectionRequest.requestReceived.email = false
      state.connectionRequest.requestAccepted.inApp = false
      state.connectionRequest.requestAccepted.push = false
      state.connectionRequest.requestAccepted.email = false
    }
  }
})

const { reducer, actions } = notifSlice
export const {
  setInitialState,
  setConnectionRequestReceiveAll,
  setConnectionRequestReceived,
  setConnectionRequestAccepted,
  setPostNotifications,
  setCommentsAndLikesNotifications,
  setPostNotificationRecipients,
  setRequestReceivedInApp,
  setRequestReceivedPush,
  setRequestReceivedEmail,
  setRequestAcceptedInApp,
  setRequestAcceptedPush,
  setRequestAcceptedEmail,
  setPostInApp,
  setPostPush,
  setPostEmail,
  setCommentsAndLikesInApp,
  setCommentsAndLikesPush,
  setCommentsAndLikesEmail,
  setAllPostNotificationRecipientsTrue,
  setAllPostNotificationRecipientsFalse,
  clearNotifications,
  setAllConnectionRequestNotificationsTrue,
  setAllConnectionRequestNotificationsFalse
} = actions
export default reducer
