/* eslint-disable react-hooks/exhaustive-deps */
import { MEASUREMENT_ID } from '@constants/events'
import routes from '@routes/AppRouter'
import { useLazyGetUserProfileQuery } from '@services/profile-service'
import { logUserOut } from '@store/slices/auth'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useRoutes } from 'react-router'
import './App.css'
import { useAppSelector } from '@hooks/redux'
import { isCypress } from '@constants/app'
import SocketHandler from '@components/utils/SocketHandler'

if (!isCypress) {
  ReactGA.initialize([
    {
      trackingId: MEASUREMENT_ID,
      gaOptions: {
        name: 'MaoneyTracker'
      }
    }
  ])
}

function App() {
  const [loading, setLoading] = useState(true)
  const { jwt, user } = useAppSelector(state => state.auth)

  const dispatch = useDispatch()

  const [getProfile, { isSuccess: profileFetched, error }] =
    useLazyGetUserProfileQuery()

  const clear = () => {
    dispatch(logUserOut())
  }

  const checkForTokenValidity = async () => {
    if (jwt) {
      if (!user?.id) {
        clear()
        setTimeout(() => {
          setLoading(false)
        }, 100)
        return
      }
      try {
        await getProfile(user.id).unwrap()
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!jwt) {
      console.log('no jwt clearing...')
      localStorage.clear()
    }
  }, [jwt])

  useEffect(() => {
    if (error && loading) {
      // @ts-expect-error
      if (error?.status === 401) {
        toast.error('Session expired.', { duration: 3000 })
        clear()
        setTimeout(() => {
          setLoading(false)
        }, 100)
      } else {
        setLoading(false)
      }
    }
  }, [error])

  useEffect(() => {
    if (profileFetched && loading) {
      setLoading(false)
    }
  }, [profileFetched])

  useEffect(() => {
    checkForTokenValidity()
  }, [])

  const element = useRoutes(routes)

  return (
    <div>
      {loading ? <div /> : element}
      <SocketHandler />
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000
        }}
      />
    </div>
  )
}

export default App
