import { createSlice } from '@reduxjs/toolkit'
const initialState = 0
const notifCounterSlice = createSlice({
  name: 'notifCounter',
  initialState,
  reducers: {
    setCount: (state, { payload }) => {
      return state === payload ? state : payload
    },
    clearCount: () => {
      return 0
    }
  }
})

const { reducer, actions } = notifCounterSlice
export const { setCount, clearCount } = actions
export default reducer
