import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authService } from '@services/auth-service'
import { connectionsService } from '@services/connections-service'
import { postsService } from '@services/posts-service'
import { profileService } from '@services/profile-service'
import { recommendationsService } from '@services/recommendations-service'
import { usersService } from '@services/users-service'
import { notificationsService } from '@services/notifications-service'
import appReducer from './slices/app'
import authReducer from './slices/auth'
import blogReducer from './slices/blogs'
import connectionsReducer from './slices/connections'
import logsReducer from './slices/logs'
import mutesReducer from './slices/mutes'
import notifReducer from './slices/notif'
import notifCounterReducer from './slices/notifCounter'
import notificationsReducer from './slices/notifications'
import postsReducer from './slices/posts'
import timerReducer from './slices/timer'
import usersReducer from './slices/users'

// persist
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  connections: connectionsReducer,
  mutes: mutesReducer,
  notifications: notificationsReducer,
  notif: notifReducer,
  posts: postsReducer,
  timer: timerReducer,
  users: usersReducer,
  blog: blogReducer,
  logs: logsReducer,
  notifCounter: notifCounterReducer,

  [authService.reducerPath]: authService.reducer,
  [connectionsService.reducerPath]: connectionsService.reducer,
  [postsService.reducerPath]: postsService.reducer,
  [usersService.reducerPath]: usersService.reducer,
  [profileService.reducerPath]: profileService.reducer,
  [recommendationsService.reducerPath]: recommendationsService.reducer,
  [notificationsService.reducerPath]: notificationsService.reducer
})

const persistConfig = {
  key: 'maoney',
  storage,
  blacklist: [
    'postsService',
    'connections',
    'connectionsService',
    'posts',
    'app',
    'recommendationsService',
    'usersService',
    'users',
    'logs'
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middlewares = [
  authService.middleware,
  connectionsService.middleware,
  postsService.middleware,
  usersService.middleware,
  profileService.middleware,
  recommendationsService.middleware,
  notificationsService.middleware
]

const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['meta.baseQueryMeta', 'meta.arg'],
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(middlewares)
  },
  devTools: import.meta.env.VITE_ENV !== 'production'
})

if ((window as any).Cypress) {
  ; (window as any)['store'] = store
}

// eslint-disable-next-line arrow-body-style
export const setupStore = (preloadedState: object) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: ['meta.baseQueryMeta', 'meta.arg']
        }
      }).concat(middlewares)
    }
  })
}

const persistor = persistStore(store)
export { persistor, store }
