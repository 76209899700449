import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ProfileSummarySkeleton = () => (
  <div className="rounded-xl w-full bg-[#ffffff] font-lato">
    <div className="flex flex-col justify-between items-center text-center align-middle mx-6">
      <div className="mt-5 w-full">
        <div className="flex flex-col justify-between items-center text-center">
          <Skeleton circle height={50} width={50} />
        </div>
        <div className="w-full">
          <Skeleton width={'80%'} height={32} />
          <Skeleton width={'50%'} height={24} />
        </div>
      </div>
      <div className="mt-3">
        <p className="text-[#140c00] font-medium text-[24px] leading-[33px]">
          {' '}
        </p>
        <p className="text-[#58534D] font-medium text-[18px] leading-[24px]"></p>
      </div>
      <div className="flex flex-col justify-start items-start">
        <div className="mt-8 flex justify-center items-center align-middle gap-2">
          <Skeleton circle height={21} width={21} />
          <div className="flex justify-center gap-3">
            <Skeleton width={'60%'} height={24} />
            <Skeleton width={56.71} height={24} />
          </div>
        </div>
        <div className="mt-4 flex justify-center items-center align-middle gap-2">
          <Skeleton circle height={21} width={21} />
          <div className="flex justify-center gap-3">
            <Skeleton width={108.45} height={24} />
            <Skeleton width={56.71} height={24} />
          </div>
        </div>
      </div>
      <div className="w-full mb-6">
        <div className="mt-12 mb-4 text-[#140C00] text-[18px]">
          <Skeleton width={'90%'} height={20} />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <br />
          <Skeleton
            width={'90%'}
            height={48}
            borderRadius={12}
            containerClassName="w-full"
          />
          <div className="mt-6 w-full">
            <div className="flex items-center justify-center w-full">
              <Skeleton width={100} height={20} />
            </div>
            <div className="mt-6 flex justify-around  text-[#58534D] items-center w-full">
              <div className="flex flex-col justify-center items-center cursor-pointer gap-2">
                <Skeleton circle height={45} width={45} />
                <Skeleton height={20} width={33.9} />
              </div>
              <div className="flex flex-col justify-center items-center cursor-pointer gap-2">
                <Skeleton circle height={45} width={45} />
                <Skeleton height={20} width={55.43} />
              </div>
              <div className="flex flex-col justify-center items-center cursor-pointer gap-2">
                <Skeleton circle height={45} width={45} />
                <Skeleton height={20} width={43.23} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ProfileSummarySkeleton
