import { createTheme } from '@mui/material'

export const maoneyMuiTheme = createTheme({
  breakpoints: {
    values: {
      sm: 760,
      lg: 1440
    }
  },
  palette: {
    primary: {
      main: '#ff9502'
    },
    secondary: {
      main: '#ffc87c'
    }
  }
})
