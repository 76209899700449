import { maoneyAccountTypes } from '@constants/auth'
import ReactGA from 'react-ga4'
import { NavigateFunction } from 'react-router-dom'
import { ConnectionType } from 'types/connections'
import { PostType } from 'types/posts'
import { TimeStamped } from 'types/shared'
import { MaoneyAccountType, UserProfileType, UserType } from 'types/users'
import { handleEncrypt } from './encrypt'

const { VITE_AUTH_TOKEN } = import.meta.env

export const setToken = (token: string) => {
  if (token) {
    localStorage.setItem(VITE_AUTH_TOKEN, token)
  }
}

export const removeToken = () => {
  localStorage.clear()
}

export const convertImageToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    if (file instanceof Blob || file instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]) // Extracting base64 data
        }
      }
      reader.onerror = error => reject(error)
      reader.readAsDataURL(file)
    } else {
      reject(new Error('Invalid file type. Expected Blob or File.'))
    }
  })

export function getProfileImageUrl(payload: string | null) {
  if (!payload) {
    return null
  }
  return payload
}

export const formatLocation = (payload: string | null) =>
  !payload ? '' : payload

export const doNothing = () => {}

export const isLastArrayItem = (arrayLength: number, index: number) =>
  index === arrayLength - 1

export const trackEvent = ({
  category,
  action,
  label
}: {
  category: string
  action: string
  label: string
}) => {
  if ((window as any).Cypress) {
    return
  }
  ReactGA.event({
    category,
    action,
    label
  })
}

export const truncateText = (text: string, maxChars: number) => {
  if (!text) {
    return ''
  }
  return text.length > maxChars ? `${text.slice(0, maxChars)}...` : text
}

/** Helper function to get the name of a maoney user (individual or organization) */
export const getMemberName = (
  member: (UserType & { account_type?: MaoneyAccountType }) | null | undefined
) => {
  if (!member) {
    return 'User'
  }
  if (!member?.account_type) {
    if (member.org_name && member?.org_name !== 'null') {
      return member.org_name || 'User'
    } else if (member.first_name) {
      return [member.first_name, member.last_name].join(' ')
    }
  }
  if (member.account_type == maoneyAccountTypes.individual) {
    return `${member.first_name} ${member.last_name}`
  } else {
    return member?.org_name || 'User'
  }
}

export const getMemberInitials = (
  member?: UserType | UserProfileType | null
) => {
  if (!member) {
    return ''
  }
  const name = getMemberName(member)
  if (!name) {
    return ''
  }
  const initials = name.split(' ').map(item => item.charAt(0).toUpperCase())
  return initials.slice(0, 2).join('')
}

export const getLoggedInPersonName = getMemberName

export const getPostDetailLink = (postId: number) => {
  const encryptedId = handleEncrypt(postId)
  return window.location.origin + `/dashboard/posts/${encryptedId}`
}

export function pluralizeWord(number: number, word: string, plural?: string) {
  if (number === undefined || number === null) {
    return word
  }
  if (number > 1 || number === 0) {
    return plural || `${word}s`
  }
  return word
}

export function removeBeforeSecondHttps(url: string) {
  const index = url.indexOf('https://', url.indexOf('https://') + 1) // Find the index of the second occurrence of 'https://'
  if (index !== -1) {
    return url.substring(index) // Return the substring starting from the second 'https://'
  }
  return url // Return the original URL if 'https://' is not found twice
}

export const getOtherUserIdFromConnection = (
  connection: ConnectionType,
  currentUser: UserType
) => {
  if (connection.receiver_id === currentUser?.id) {
    return connection.sender_id
  }
  if (connection.sender_id === currentUser?.id) {
    return connection.receiver_id
  }
  return connection.sender_id
}

/** Extract the error from a server response */
export const extractServerError = (error: any) =>
  error?.data?.message || error?.data?.error || 'Something went wrong'

/** Generate a random id */
export function generateRandomId(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const sortByUpdatedAt = (array: (TimeStamped & any)[]) =>
  array.sort((a, b) => {
    const dateA = new Date(a.updated_at)
    const dateB = new Date(b.updated_at)
    return dateB.getTime() - dateA.getTime()
  })

export const sortByCreatedAt = (array: (TimeStamped & any)[]) =>
  array.sort((a, b) => {
    const dateA = new Date(a.created_at)
    const dateB = new Date(b.created_at)
    return dateB.getTime() - dateA.getTime()
  })

export function formatPostDate(inputDateString: string) {
  const inputDate = new Date(inputDateString)

  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date'
  }

  const formattedDate = inputDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  const daySuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  return formattedDate.replace(
    /\d{1,2}/,
    day => `${day}${daySuffix(parseInt(day))}`
  )
}

function handleZeroAndNegative(inputValue: number) {
  return Math.max(0, inputValue)
}

function calculatePercentageAndStatus(
  requiredAmount: number,
  currentAmount: number
) {
  if (requiredAmount === 0) {
    throw new Error(
      'Required amount cannot be zero. Please provide a non-zero value.'
    )
  }
  const percentage = (currentAmount / requiredAmount) * 100
  const formattedPercentage = parseFloat(percentage.toFixed(2))
  const statusString = `$${currentAmount?.toLocaleString()} so far`
  // const statusString = `$${formatNumber(currentAmount)} so far`
  return {
    percentage: formattedPercentage,
    status: statusString
  }
}

export const handlePercentage = (
  requiredAmount: number,
  currentAmount: number
) => {
  try {
    if (requiredAmount === 0 && currentAmount === 0) {
      return {
        percentage: 0,
        status: 'No progress'
      }
    }

    const result = calculatePercentageAndStatus(requiredAmount, currentAmount)
    return result
  } catch (error: any) {
    console.error(error.message)
    return {
      percentage: 0,
      status: '0'
    }
  }
}

export const getPostProgressPercentage = (post: PostType) =>
  handleZeroAndNegative(
    handlePercentage(post?.required_amount, post?.current_amount)?.percentage
  )

export const deepClone = (obj: any) => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const clone: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    clone[key] = deepClone(obj[key])
  }

  return clone
}

export const goToUserProfile = ({
  navigate,
  user,
  currentUser
}: {
  navigate: NavigateFunction
  user: UserType
  currentUser: UserType
}) => {
  if (!user?.id) {
    return
  }
  if (user?.id !== currentUser?.id) {
    navigate(`/dashboard/user/${user?.id}`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  } else {
    navigate(`/dashboard/profile`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

export const userDisplaysLocation = (user: UserType) =>
  user?.location_status === 0

export const getUserFirstName = (user: UserType | null) => {
  if (!user) {
    return ''
  }
  if (isOrganization(user)) {
    return user?.org_name
  }
  return user?.first_name
}

export function arraysAreEqual(arr1: any[], arr2: any[]) {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Iterate over each element and compare
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  // If all elements are the same, return true
  return true
}

export const isOrganization = (user: UserType) => {
  const hasNoFirstName = !user?.first_name || user?.first_name === 'null'
  const hasNoLastName = !user?.last_name || user?.last_name === 'null'
  const hasOrgName = user?.org_name
  if (user?.account_type === maoneyAccountTypes.organization) {
    return true
  }
  if (hasNoFirstName && hasNoLastName && hasOrgName) {
    return true
  }
  return false
}

export function insertItemInArray(array: any[], index: number, newItem: any) {
  const newArray = Array.isArray(newItem) ? newItem : [newItem]
  // Create a new array with the elements before the insertion point
  const before = array.slice(0, index)
  // Create a new array with the elements after the insertion point
  const after = array.slice(index)
  // Concatenate before array, new array, and after array
  return before.concat(newArray, after)
}
