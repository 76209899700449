import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/query/react'
import { POST, customBaseQuery } from './util'
import {
  IndividualSignupPayload,
  LoginResponse,
  OrganizationSignupPayload,
  SignUpResponse,
  VerifyOtpPayload,
  VerifyOtpResponse
} from 'types/auth'

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, { identifier: string }>({
      query: data => ({
        url: apiConfig.Onboarding.Login,
        method: POST,
        body: data
      })
    }),
    signup: builder.mutation<
      SignUpResponse,
      IndividualSignupPayload | OrganizationSignupPayload
    >({
      query: data => ({
        url: apiConfig.Onboarding.SignUp,
        method: POST,
        body: data
      })
    }),
    sendOtp: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.SendOtp,
        method: POST,
        body: data
      })
    }),
    verifyOtp: builder.mutation<VerifyOtpResponse, VerifyOtpPayload>({
      query: data => ({
        url: apiConfig.Onboarding.VerifyOTP,
        method: POST,
        body: data
      })
    }),
    resendOtp: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.ResendOTP,
        method: POST,
        body: data
      })
    }),
    getUserGoogleProfile: builder.query({
      query: ({ id }) => ({
        url: `${apiConfig.GoogleApi}?access_token=${id}`
      })
    }),
    googleProfile: builder.mutation<
      any,
      {
        displayName: string
        emails: string
        id: string
        account_type: 'individual' | 'organization'
        org_name: string
        country_origin: string
      }
    >({
      query: body => {
        const user = { user: body }
        return {
          url: apiConfig.User.GoogleLogin,
          method: POST,
          body: user
        }
      }
    }),
    changeIdentifier: builder.mutation({
      query: ({
        identifier,
        identifierType,
        userId
      }: {
        identifier: string
        identifierType: 'email' | 'phone_number'
        userId: number
      }) => ({
        url: `${apiConfig.User.ChangeIdentifier}/${userId}`,
        method: 'POST',
        body: {
          identifier,
          identifierType
        }
      })
    }),
    verifyChangeIdentifier: builder.mutation({
      query: ({ userId, otp }: { userId: number; otp: string }) => ({
        url: apiConfig.User.verifyChangeIdentifier(userId),
        method: 'POST',
        body: {
          otp
        }
      })
    })
  })
})

export const {
  useLoginMutation,
  useSignupMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useSendOtpMutation,
  useLazyGetUserGoogleProfileQuery,
  useGoogleProfileMutation,
  useChangeIdentifierMutation,
  useVerifyChangeIdentifierMutation
} = authService
