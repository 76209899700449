import React from 'react'

export const lazy = (factory: any) =>
  React.lazy(() => factory().catch(importErrorHandler))

export function importErrorHandler(err: any) {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem('last-reload')
  const time = timeStr ? Number(timeStr) : null
  const now = Date.now()

  // If the last reload time is more than 10 seconds ago, reload the page
  const isReloading = !time || time + 10000 < now
  if (isReloading) {
    sessionStorage.setItem('last-reload', String(now))
    window.location.reload()
    return { default: () => null }
  }

  // We let ErrorBoundary handle the error
  throw new Error(err)
}
