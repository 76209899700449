import Loader from '@components/Loaders/Loader'
import { ThemeProvider } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import CacheBuster from 'react-cache-buster'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
// import * as Sentry from '@sentry/react'
import { GOOGLE_CLIENT_ID } from '@constants/auth'
import { persistor, store } from '@store'
import { version } from '../package.json'
import { maoneyMuiTheme } from '../themes/maoneyMuiTheme'
import App from './App'
import './index.css'
import { register } from './serviceWorker'

const isProduction = import.meta.env.VITE_ENV === 'production'
const isStaging = import.meta.env.VITE_ENV === 'staging'

if (isProduction || isStaging) {
  // Sentry.init({
  //   environment: import.meta.env.VITE_ENV,
  //   dsn: 'https://d79e265f36ca2bb500c9e9f02ba3e6a1@o4507335452852224.ingest.us.sentry.io/4507335458160640',
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration()
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: [
  //     'https://dev.maoney.com',
  //     'https://staging.maoney.com',
  //     'https://maoney.com',
  //     'https://www.maoney.com'
  //   ],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // })
}

const tagManagerArgs = {
  gtmId: 'G-TBK1HR1KB0'
}

TagManager.initialize(tagManagerArgs)
const helmetContext = {}

console.log(`v${version}`)
if (isProduction) {
  const noop = () => {} // noop == 'no operation
  // console.log = noop
  console.error = noop
  console.warn = noop
  console.info = noop
  console.debug = noop
  console.trace = noop
  console.table = noop
  console.group = noop
  console.groupEnd = noop
  console.groupCollapsed = noop
  console.assert = noop
  console.clear = noop
  console.count = noop
  console.countReset = noop
  console.time = noop
  console.timeEnd = noop
  console.timeLog = noop
  console.dir = noop
  console.dirxml = noop
  console.profile = noop
  console.profileEnd = noop
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={!isProduction} //If true, the library writes verbose logs to console.
    loadingComponent={<Loader type="regular" />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <ThemeProvider theme={maoneyMuiTheme}>
            <Provider store={store}>
              <PersistGate loading={'Loading....'} persistor={persistor}>
                <App />
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </GoogleOAuthProvider>
  </CacheBuster>
)

register()
