export const maoneyAccountTypes = {
  organization: 'organization',
  individual: 'individual'
}

export const locationStatus = {
  hidden: 1,
  visible: 0
}

export const GOOGLE_CLIENT_ID =
  '81152818944-iqnbu82noan5telckn476140c892phgq.apps.googleusercontent.com'
