import { createSlice } from '@reduxjs/toolkit'

const initialState: any[] = []

const mutesSlice = createSlice({
  name: 'mutes',
  initialState,
  reducers: {
    clearMutes: () => {
      return []
    },
    addMute: (state, { payload }) => {
      // Add the new string to the array if it doesn't already exist
      if (!state.includes(payload)) {
        state.push(payload)
      }
    },
    removeMute: (state, { payload }) => {
      // Remove the specified string from the array
      return state.filter(item => item !== payload)
    }
  }
})

const { reducer, actions } = mutesSlice
export const { addMute, clearMutes, removeMute } = actions
export default reducer
