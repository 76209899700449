import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserType } from 'types/users'

const initialState: UserType[] = []

/** Reducer to store all the users in the system */
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (_state, action: PayloadAction<UserType[]>) => [
      ...action.payload.filter(user => !user.deleted_at)
    ],
    setMoreUsers: (state, action: PayloadAction<UserType[]>) => {
      const newUsers = action.payload
      const updatedUserIds: number[] = []
      const updatedPreviousState = [...state].map(user => {
        const newUserObj = newUsers.find(newUser => newUser.id === user.id)
        if (newUserObj) {
          updatedUserIds.push(user.id)
          return newUserObj
        }
        return user
      })
      const actualNewUsers = [...newUsers].filter(
        user => !updatedUserIds.includes(user.id)
      )
      return [...updatedPreviousState, ...actualNewUsers]
    }
  }
})

export const { setUsers, setMoreUsers } = usersSlice.actions

export default usersSlice.reducer
