import React from 'react'

const NoMatch = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-[#f0f0f0]">
      <h2 className="text-[2em] text-[#333] mb-[10px]">404 - Not Found</h2>
      <p className="text-[1.2em] text-[#555]">
        Sorry, the page you are looking for might not exist.
      </p>
    </div>
  )
}

export default NoMatch
