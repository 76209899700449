import React from 'react'
import HeaderSkeleton from '../navigation/HeaderSkeleton'
import Skeleton from 'react-loading-skeleton'

const EditProfileSkeleton = () => (
  <div className="min-h-screen overflow-y-hidden">
    <HeaderSkeleton />
    <div className="flex overflow-hidden xs:flex-col xs:mt-[36px]">
      <div className="bg-white w-[248px] p-2 shrink-0 font-lato h-[calc(100vh-120px)] flex flex-col xs:w-auto xs:mx-4 xs:h-auto xs:rounded-lg xs:p-0">
        <div className="min-h-[64px] px-4 pt-8 xs:pt-4">
          <Skeleton
            width={100}
            borderRadius={8}
            className="xs:h-[20px] h-[32px]"
          />
        </div>
        <ul className="xs:flex w-full xs:space-x-5 mt-10 xs:px-4 ">
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
          <Skeleton containerClassName="w-full" className="h-10 xs:h-5" />
        </ul>
      </div>
      <div className="flex-1 p-10 overflow-y-auto bg-[#F1F1F1] max-h-[calc(100vh-120px)] xs:p-4 min-h-[180px]">
        <div className="bg-white py-6 px-10 xs:p-4 rounded-xl">
          <Skeleton height={30} width={'30%'} />
          <Skeleton className="h-5 xs:h-4 mt-2 xs:w-full w-[30%]" />
          <Skeleton className="mt-4 w-full" height={1} />
          {/* form */}
          <div className="mt-4 flex items-start flex-wrap gap-[30px]">
            <div className="flex flex-col mt-5 items-center">
              <div className="relative">
                <Skeleton circle height={107} width={107} />
                <div
                  className="absolute xs:right-3"
                  style={{ right: '2px', bottom: '-0.15rem' }}
                >
                  <Skeleton className="xs:h-6 xs:w-6 w-8 h-8" circle />
                </div>
              </div>
              <Skeleton width={90} height={20} />
              <Skeleton width={70} height={20} className="mt-1" />
            </div>
            <div className="xl:w-[740px] xl:mx-auto mx-8 md:mx-0 xs:mx-0 w-full">
              <div className="overflow-y-auto scrollbar-none">
                <div className="flex items-center justify-center mt-2 xs:mt-2"></div>
                <div className="flex items-center sm:flex-col gap-6 mt-5 xs:mt-8">
                  <div className="flex flex-col gap-2 w-full">
                    <Skeleton width={60} height={18} />
                    <Skeleton height={58} className="rounded-lg w-full p-4" />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <Skeleton width={60} height={18} />
                    <Skeleton height={58} className="rounded-lg w-full p-4" />
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full mt-3">
                  <Skeleton width={60} height={18} />
                  <Skeleton height={120} className="rounded-lg w-full p-4" />
                </div>
                <div className="flex flex-col gap-2 w-full mt-3">
                  <Skeleton width={60} height={18} />
                  <Skeleton height={120} className="rounded-lg w-full p-4" />
                </div>
                <div className="flex flex-col gap-2 w-full mt-3">
                  <Skeleton width={60} height={18} />
                  <Skeleton height={50} className="rounded-lg w-full p-4" />
                  <Skeleton height={70} className="rounded-lg w-full p-4" />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={100} height={18} />
                  <Skeleton width={44} height={24} borderRadius={5} />
                </div>
              </div>
              <div className="mt-16 xs:mt-6">
                <Skeleton borderRadius={12} height={54} />
              </div>
            </div>
          </div>
          {/* end form */}
        </div>
      </div>
    </div>
  </div>
)

export default EditProfileSkeleton
