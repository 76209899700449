import { createApi } from '@reduxjs/toolkit/query/react'
import { PUT, customBaseQuery } from './util'
import apiConfig from '@config/apiConfig'
import { LoyaltyPointType, UserProfileType } from '../types/users'

export const profileService = createApi({
  reducerPath: 'profileService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    editProfile: builder.mutation<any, any>({
      query: data => ({
        url: `/api/users/update-profile`,
        method: PUT,
        body: data,
        prepareHeaders: (headers: {
          set: (arg0: string, arg1: string) => void
        }) => {
          headers.set('Content-Type', 'multipart/form-data')
          return headers
        },
        formData: true
      })
    }),
    updateUserDetails: builder.mutation({
      query: ({ userId, ...rest }) => ({
        url: `${apiConfig.User.Users}/${userId}`,
        method: PUT,
        body: rest
      })
    }),
    getUserProfile: builder.query<
      { status: 'success'; user: UserProfileType },
      number
    >({
      query: profileId => ({
        url: `${apiConfig.User.GetUserProfileById}${profileId}`
      })
    }),
    getLoyaltyPoints: builder.query<
      { status: number; data: LoyaltyPointType[]; total_points: number },
      number
    >({
      query: userId => `${apiConfig.User.LoyaltyPoints}${userId}`
    }),
    getUser: builder.query({
      query: userId => ({
        url: `${apiConfig.User.Users}/${userId}?populate[imageUrl]=true&populate[social_point]=true*&populate[user_location]=true&populate[user_reference]=true&populate[user_network]=true&populate[user_organisations]=true&populate[products][populate]=*`
      })
    }),
    deleteAccount: builder.mutation({
      query: userID => ({
        url: `${apiConfig.User.DeleteAccount}`,
        method: 'DELETE',
        body: {
          user_id: userID
        }
      })
    }),
    uploadImage: builder.mutation({
      query: body => ({
        url: `${apiConfig.User.Upload}`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useEditProfileMutation,
  useUpdateUserDetailsMutation,
  useLazyGetUserProfileQuery,
  useGetUserProfileQuery,
  useGetLoyaltyPointsQuery,
  useGetUserQuery,
  useDeleteAccountMutation,
  useUploadImageMutation
} = profileService
