import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PostType } from 'types/posts'
import { PostsState } from '@typs/store'

const initialState: PostsState = {
  pageCount: 0,
  posts: [],
  postToUpdate: null,
  postToDelete: null,
  commentToDelete: null,
  shareModalState: { visible: false, post: null },
  imagesModalState: { visible: false, images: [], index: 0 },
  postToBeNotifiedAbout: null,
  subscribersMessagePost: null,
  deletedCommentDetails: null,
  newlyEditedPostId: null,
  newlyDeletedPostId: null
}

/** Reducer to manage posts */
const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    addNewPost: (state, action: PayloadAction<PostType>) => {
      state.posts = [action.payload, ...state.posts]
    },
    setNewPostsWithPage: (
      state,
      action: PayloadAction<{ posts: PostType[]; page: number }>
    ) => {
      const previousPostIds = state.posts.map(p => p.id)
      const newPosts = action.payload.posts.filter(
        post => !previousPostIds.includes(post.id)
      )
      const page = action.payload.page
      state.posts = [...state.posts, ...newPosts]
      state.pageCount = page
    },
    setPosts: (state, action: PayloadAction<PostType[]>) => {
      state.posts = action.payload
    },
    setPostToUpdate: (
      state,
      action: PayloadAction<PostsState['postToUpdate']>
    ) => {
      state.postToUpdate = action.payload
    },
    setPostToDelete: (state, action) => {
      state.postToDelete = action.payload
    },
    setPostToShare: (state, action) => {
      state.shareModalState = { visible: true, post: action.payload }
    },
    clearPostToShare: state => {
      state.shareModalState = {
        visible: false,
        post: state.shareModalState.post
      }
    },
    setCommentToDelete: (state, action) => {
      state.commentToDelete = action.payload
    },
    setDeletedCommentDetails: (state, action) => {
      state.deletedCommentDetails = action.payload
    },
    setImagesToDisplay: (state, action) => {
      state.imagesModalState = {
        visible: true,
        images: action.payload.images,
        index: action.payload.index || 0
      }
    },
    clearImagesToDisplay: state => {
      state.imagesModalState = {
        visible: false,
        images: state.imagesModalState.images,
        index: 0
      }
    },
    refreshSinglePost: (
      state,
      action
      // action: PayloadAction<{ postId: number; data: any }>
    ) => {
      const newPosts = [...(state.posts || [])].map(post => {
        if (post.id === action.payload.postId) {
          return action.payload.data
        } else {
          return post
        }
      })
      state.posts = newPosts
    },
    updatePostsByLikeAction: (
      state,
      action: PayloadAction<{
        action: 'like' | 'unlike'
        commentId: number
        userId: number
      }>
    ) => {
      const { action: userAction, commentId, userId } = action.payload
      const clonedFeedsListing = [...state.posts]
      const feedIndex = clonedFeedsListing.findIndex(
        feed =>
          feed.comments &&
          feed.comments.some(comment => comment.id === commentId)
      )
      if (feedIndex !== -1) {
        const clonedFeed = { ...clonedFeedsListing[feedIndex] }
        const commentIndex = clonedFeed.comments.findIndex(
          comment => comment.id === commentId
        )
        if (commentIndex !== -1) {
          const clonedComment = { ...clonedFeed.comments[commentIndex] }
          if (userAction === 'like') {
            if (!clonedComment.likes) {
              clonedComment.likes = []
            }
            clonedComment.likes.push({
              comment_id: commentId,
              user_id: userId
            })
          } else if (userAction === 'unlike') {
            clonedComment.likes = clonedComment.likes.filter(
              like =>
                !(like.comment_id === commentId && like.user_id === userId)
            )
          }
          clonedFeed.comments[commentIndex] = clonedComment
          clonedFeedsListing[feedIndex] = clonedFeed
          state.posts = clonedFeedsListing
        }
      }
    },
    setPostToBeNotifiedAbout: (
      state,
      action: PayloadAction<PostType | null>
    ) => {
      state.postToBeNotifiedAbout = action.payload
    },
    setSubscribersMessagePost: (
      state,
      action: PayloadAction<PostType | null>
    ) => {
      state.subscribersMessagePost = action.payload
    },
    setNewlyEditedPostId: (state, action: PayloadAction<number | null>) => {
      state.newlyEditedPostId = action.payload
    },
    setNewlyDeletedPostId: (state, action: PayloadAction<number | null>) => {
      state.newlyDeletedPostId = action.payload
    }
  }
})

export const {
  setNewPostsWithPage,
  setPosts,
  setPostToUpdate,
  setPostToDelete,
  setPostToShare,
  clearPostToShare,
  setCommentToDelete,
  refreshSinglePost,
  updatePostsByLikeAction,
  setImagesToDisplay,
  clearImagesToDisplay,
  addNewPost,
  setDeletedCommentDetails,
  setPostToBeNotifiedAbout,
  setSubscribersMessagePost,
  setNewlyEditedPostId,
  setNewlyDeletedPostId
} = postsSlice.actions

export default postsSlice.reducer
